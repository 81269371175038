import { Disclaimer, PrivacyPolicy, RefundPolicy, Suggestions } from "./ModalContent/ModalContent"

export const footerSocialLinkIcons = [
  {
    linkIcon: "facebook",
    linkUrl: "https://www.facebook.com/profile.php?id=100094980393013&mibextid=ZbWKwL"
  },
  {
    linkIcon: "instagram",
    linkUrl: "https://instagram.com/astrologicalstars249"
  },
  {
    linkIcon: "whatsapp",
    linkUrl: "https://wa.me/message/GTQ4WUYJTIUSN1"
  },
  {
    linkIcon: "youtube",
    linkUrl: "https://www.youtube.com/@AstrologicalStars"
  },
]

export const footerPolicyMenus = [
  {
    text: "Disclaimer",
    content: <Disclaimer />
  },
  {
    text: "Refund Policy",
    content: <RefundPolicy />
  },
  {
    text: "Privacy Policy",
    content: <PrivacyPolicy />
  },
  {
    text: "Suggestions",
    content: <Suggestions />
  },
]