import './CheckboxComponent.scss';

function CheckboxComponent({
  label,
  name,
  value,
  errors,
  touched,
  handleChange,
  isChecked
}) {
  return (
    <div className='checkbox-container'>
      <div className="checkbox-area">
        <input
          type='checkbox'
          name={name}
          value={value}
          onChange={handleChange}
          checked={isChecked}
          className='checkbox-component'
        />
        <span className='checkbox-label'>{label}</span>
      </div>
      {errors && touched && <span className='formik-input-errors'>{errors}</span>}
    </div>
  )
}

export default CheckboxComponent