export const RefundPolicy = () => (
  <>
    <span>At Astrologicalstars.com we are committed to providing high-quality services and ensuring customer satisfaction. We understand that there may be circumstances where you may request a refund. This Refund Policy outlines the terms and conditions under which refunds will be granted for our services.</span>
    <ul>
      <li>Refunds are subject to our review and approval, and they are not guaranteed</li>
      <li>Refunds will not be provided if the service was delivered</li>
      <li>To request a refund, please contact us via email or phone number</li>
      <li>If you cancel a service before it has been provided, you may be eligible for a full refund</li>
      <li>If you cancel a service after it has been partially delivered,Refunds will not be provided</li>
      <li>No refunds will be granted if the customer provides incorrect or incomplete information that affects the delivery of the service</li>
    </ul>
    <p>Policy Updates: We reserve the right to update and revise this Refund Policy at any time. Any changes to this policy will be posted on our website, and the revised policy will apply to all services purchased after the effective date of the updated policy.</p>
    <p>By engaging with our services, you agree to abide by the terms and conditions outlined in this Refund Policy. It is your responsibility to review and understand this policy before making a purchase. </p>
    <p>If you have any questions or concerns, please contact our customer support team for clarification. Thank you for choosing astrologicalStars and we appreciate the opportunity to serve you.</p>
  </>
)

export const PrivacyPolicy = () => (
  <>
    <span>Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our service website. By using our website, you agree to the terms and practices described in this policy.</span>
    <ol>
      <li>Personal Information</li>
      <span> We may collect personal information such as your name, email address, phone number, and other contact details when you provide them voluntarily, for instance when you fill out a contact form, sign up for our services, or subscribe.</span>

      <li>How We Use Your Information:</li>
      <span>We use the information collected to provide you with the services you request and to respond to your inquiries.</span>

      <li>Information Sharing:</li>
      <span>We do not sell, trade, or share your personal information with third parties.</span>
    </ol>
    <ul>
      <li>Policy Changes:</li>
      <span>We reserve the right to update and amend this Privacy Policy. Any changes will be posted on our website with the updated effective date.</span>

      <li>Contact Us:</li>
      <span>If you have any questions or concerns about this Privacy Policy or your personal information, please contact us at astrologicalstars249@gmail.com</span>
    </ul>
    <span>By using our website, you agree to the terms and practices described in this Privacy Policy. It is your responsibility to review and understand this policy.Thank you for choosing astrological stars</span>
  </>
)

export const Disclaimer = () => (
  <>
    <span>Prior to using this website, we strongly advise visitors to carefully review this agreement (referred to as the "Agreement"). By accessing or using this website, you are indicating your consent to be bound by the terms and conditions outlined within this Agreement. Your visit to Astrologicalstars.com / Amanpreet singh signifies your unreserved acceptance of the various terms and conditions described on this website and in the disclaimer section of this Agreement. You will be obligated to adhere to the terms of the disclaimer, which may be modified, altered, changed, or deleted in this Agreement and on the website from time to time. By entering this website, you are acknowledging that Astrologicalstars.com / Amanpreet singh shall not be held responsible for any direct or indirect losses incurred from using the information and materials found on this website.</span>
    <span>Disclaimer for Astrologicalstars.com</span>
    <ol>
      <li>Use of Services</li>
      <span>You are responsible for choosing the Services that best suit your needs and goals. Astrologicalstars.com / Amanpreet singh and its representatives assume that you take full responsibility for your selection.</span>
      <li>Limitation of Liability</li>
      <span>To the maximum extent permitted by applicable law, Astrologicalstars.com / Amanpreet singh and its representatives shall not be liable for any special, incidental, punitive, consequential, or indirect damages or losses, including but not limited to loss of data, lost profits, or cost of cover, arising from the use of the Services, regardless of the theory of liability. This limitation of liability applies even if Astrologicalstars.com / Amanpreet singh  or its representatives have been informed of the possibility of such damages or losses.</span>
      <li>Disclaimer of Warranties</li>
      <span>Your use of the service is solely at your own risk. Astrologicalstars.com / Amanpreet singh  expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Astrologicalstars.com / Amanpreet singh  makes no warranty that: (i) the service will meet your requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results obtained from the use of the service will be accurate or reliable, (iv) any products, services, information, or other materials purchased or obtained through the services or any transactions entered into through the services, or that any of these will meet your expectations, and (v) any errors in the Service will be corrected.</span>
      <li>Accuracy of Content</li>
      <span>Every effort has been made to ensure the accuracy of the content in the Courses. However, Astrologicalstars.com / Amanpreet singh makes no warranties or representations regarding the accuracy of the material or the significance of any aspect of the astrological horoscopes/Services. They cannot be held responsible for any interpretations or use that may be made of it and do not accept any responsibility for errors and omissions.</span>
      <li>Astrological Opinion</li>
      <span>The horoscopes/Services should be regarded as the opinion of an individual based on their knowledge and experience of Vedic astrological texts. It's important to note that the opinions of other astrologers may differ.</span>
      <li>Acceptance</li>
      <span>Your use of this website indicates your acceptance of the terms, conditions, disclaimers, and privacy policy outlined above. If you do not accept these terms, you may exit the site.</span>
    </ol>
    <span>If you have any questions or require information about Astrologicalstars.com, please feel free to contact us at astrologicalstars249@gmail.com</span>
  </>
)

export const Suggestions = () => (
  <>
    <p>We value your feedback and suggestions to enhance your experience on our service website. Your input is invaluable in helping us continually improve and tailor our offerings to meet your needs. Please feel free to share your thoughts, ideas, or concerns with us, and we will carefully consider your input.</p>
    <p>Here are a few areas where your suggestions can make a difference:</p>
    <ol>
      <li>Service Enhancements: If you have ideas for improving our existing services or would like to see new features or options added, we'd love to hear from you. Your suggestions can help us better cater to your needs and preferences.
      </li>
      <li>User Experience: Your feedback on the usability, navigation, and overall experience of our website is essential. Let us know if you encounter any challenges or if you have ideas to make your visit smoother and more enjoyable.</li>
      <li>Content Suggestions: If you'd like to see specific content, articles, guides, or resources related to our services, please tell us. Your input will help us provide you with the information you find most valuable.</li>
      <li>Technical Issues: If you encounter any technical issues, such as broken links, slow loading times, or other website-related problems, please report them here. We aim to ensure a seamless and trouble-free experience for all users.</li>
      <li>Customer Support: Share your thoughts on our customer support services. Whether it's praise for exceptional service or suggestions for improvement, we welcome your input to serve you better.</li>
      <li>Privacy and Security: If you have concerns about privacy and security on our website, please let us know. Your suggestions will help us maintain a safe and trustworthy online environment.</li>
      <li>Privacy and Security: If you have concerns about privacy and security on our website, please let us know. Your suggestions will help us maintain a safe and trustworthy online environment.</li>
    </ol>
    <p>Your feedback is important to us, and we're committed to listening and responding to your suggestions. We may not be able to implement every idea, but we will carefully review each one. Together, we can make our service website even better.</p>
    <p>
      Please use the form below to submit your suggestions or contact our customer support team directly at astrologicalstars249@gmail.com if you have specific questions or concerns. Thank you for being a valued member of our online community and for helping us enhance your experience. Your input is highly appreciated.
    </p>
  </>
)