import { ReactComponent as AppLogo } from "./Icon/applogo.svg";
import { ReactComponent as ZodiacLogo } from "./Icon/ZodiacLogo.svg";
import { ReactComponent as PlusRound } from "./Icon/plusRound.svg";
import { ReactComponent as LocationLogo } from "./Icon/locationLogo.svg";
import { ReactComponent as CallLogo } from "./Icon/callLogo.svg";
import { ReactComponent as StarLogo } from "./Icon/starIcon.svg";
import { ReactComponent as TickBox } from "./Icon/tick.svg";
import { ReactComponent as CircleLeft } from "./Icon/circleLeft.svg";
import { ReactComponent as GeminiLogo } from "./Icon/geminiLogo.svg";
import { ReactComponent as AddToCart } from "./Icon/add-to-cart.svg";

function IconRef({
  applogo,
  zodiacLogo,
  plusRoundIcon,
  locationLogo,
  callLogo,
  starLogo,
  tickBox,
  circleLeft,
  geminiLogo,
  addToCart
}) {
  return (
    <>
      {applogo && <AppLogo />}
      {zodiacLogo && <ZodiacLogo />}
      {plusRoundIcon && <PlusRound />}
      {locationLogo && <LocationLogo />}
      {callLogo && <CallLogo />}
      {starLogo && <StarLogo />}
      {tickBox && <TickBox />}
      {circleLeft && <CircleLeft />}
      {geminiLogo && <GeminiLogo />}
      {addToCart && <AddToCart />}
    </>
  )
}

export default IconRef