import classNames from "classnames";
import { useNavigate } from "react-router-dom";
// import { Checkbox } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { CustomButton, Footer, SvgIcon, TileComponent } from "../../../components";
import { Formik } from "formik";
import { URLRoutes } from "../../../URLRoutes";
import './ServiceDescription.scss';

function ServiceDescription({
  serviceTitle,
  servicePrice,
  serviceImage,
  serviceListTitle,
  serviceList,
  servicePoetLines,
  servicesDescription,
  isSingleQuestion,
  singleQuestions,
  isGoldService,
  index
}) {

  const [tileIndex, setTileIndex] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client: { services, servicesPayment } } = URLRoutes;

  const handleToggleTile = (index) => {
    if (tileIndex === index) {
      setTileIndex(null);
    } else {
      setTileIndex(index);
    }
  }

  // const renderServiceList = (item, index) => {
  //   return (
  //     <div className='servicecard-list-data' key={`${index}`}>
  //       <SvgIcon logoName={{ tickBox: true }} size={{ customSize: "1.5rem" }} />
  //       <span className='servicecard-list-data-text'>{item}</span>
  //     </div>
  //   )
  // }

  const renderServiceDescription = (item, index) => {
    return (
      <p key={`renderDescriptionList-index-${index}`}>{item}</p>
    )
  }

  const handleSingleQuestionsMap = (values, handleChange, handleSubmit, errors, touched) => {
    return singleQuestions?.map((item, index) => {
      const { category, questionList } = item;

      const isOpen = tileIndex === index;

      const handleTileOpen = () => {
        handleToggleTile(index);
      }

      const renderQuestionList = (item, index) => {
        return (
          <div>
            <input
              type="radio"
              id={`singleQuestion_${index}`}
              name="singleQuestionName"
              value={item}
              checked={values.singleQuestionName === item}
              onChange={handleChange}
              className="single-question-radio-button"
            />
            <span className="single-question-option-text" key={`renderQuestionList-index-${index}`}>{item}</span>
          </div>
        )
      }

      return (
        <TileComponent key={`rendersingleQuestions-key-${index}`} baseclassname={classNames(['services-description-single-question-tile-container'], {
          'services-description-single-question-tile-open': isOpen
        })}>
          <div className="services-description-single-question-category-name-container" onClick={handleTileOpen}>
            <div className="services-description-single-question-category-namebox">
              <SvgIcon logoName={{ starLogo: true }} size={{ customSize: "4rem" }} baseClassname={"single-question-tile-icon"} />
              <span className="services-description-single-question-category">{category}</span>
            </div>
            <SvgIcon logoName={{ plusRoundIcon: true }} size={{ customSize: "3rem" }} baseClassname={"single-question-tile-icon"} />
          </div>
          {isOpen && <TileComponent>{questionList?.map(renderQuestionList)}</TileComponent>}
        </TileComponent>
      )
    })
  }

  const handleOpenPaymentForm = () => {
  }

  const handleNavigateBack = () => {
    navigate(services)
  }

  const handleAddToCart = () => {
    dispatch({
      type: "ADD_TO_CART", payload: {
        serviceName: serviceTitle,
        serviceCardPrice: servicePrice
      }
    });
    navigate(services);
  }

  const handleSingleQuestionSubmit = (values) => {
    dispatch({
      type: "SINGLE_QUESTION_BUY", payload: {
        question: values.singleQuestionName,
        servicePrice: servicePrice,
      },
    })
    navigate(servicesPayment);
  }

  return (
    <>
      <div className="services-description-title-container">
        <CustomButton
          noBackground
          noPadding
          onClick={handleNavigateBack}
        >
          <SvgIcon
            logoName={{ circleLeft: true }}
            size={{ customSize: "4rem" }}
            baseClassname={"service-description-title-button-icon"}
          />
        </CustomButton>
      </div>
      <TileComponent goldBlueTheme={isGoldService} baseclassname={classNames(["service-description-container"], {
        "astro-numero-desc": isGoldService,
      })}>
        <Formik
          initialValues={{
            singleQuestionName: ""
          }}
          onSubmit={handleSingleQuestionSubmit}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <>
                <h2 className="services-description-title">{serviceTitle}</h2>
                <div className="service-description-data-container">
                  <TileComponent goldBlueTheme={isGoldService} baseclassname={"services-description-about-tile"}>
                    {servicesDescription?.map(renderServiceDescription)}
                    {servicePoetLines &&
                      <div className="services-description-poet-container">
                        {servicePoetLines?.map(renderServiceDescription)}
                      </div>}
                  </TileComponent>
                  <div className="service-description-image-container" style={{ backgroundImage: `url(${serviceImage})` }}>
                    <TileComponent goldBlueTheme={isGoldService} transparentBlueTheme={!isGoldService} baseclassname={"services-description-price-tag"}>{servicePrice}</TileComponent>
                  </div>
                </div>
                {/* {serviceList && <div className="service-description-feature-container">
                <TileComponent baseclassname={"service-description-feature-tile"}>
                  <span className="services-container-feature-title">{serviceListTitle}</span>
                  <div className="services-container-feature-list-box">{serviceList?.map(renderServiceList)}</div>
                </TileComponent>
              </div>} */}
                {isSingleQuestion && <div className="service-description-single-question-choose-container">
                  <h2>Please Select only one question from below categories!</h2>
                  {handleSingleQuestionsMap(values, handleChange, handleSubmit, errors, touched)}

                </div>}
                <div className="service-description-book-now-button-container">
                  {isSingleQuestion ? <CustomButton type={'submit'} goldBlueTheme={isGoldService} blueTheme={!isGoldService} round onClick={handleOpenPaymentForm}>
                    <span>Book Now & Pay</span>
                  </CustomButton> :
                    <CustomButton goldBlueTheme={isGoldService} blueTheme={!isGoldService} round onClick={handleAddToCart}>
                      <span>Add to Cart</span>
                    </CustomButton>}
                </div>
              </>
            </form>
          )}
        </Formik>
      </TileComponent>
      <Footer />
    </>
  )
}

export default ServiceDescription