import SvgIcon from "../SvgIcon/SvgIcon";
import './AddToCart.scss';

function AddToCart({
  customSize,
  itemsTotal
}) {
  return (
    <div className="addtocart-container">
      <span className="addtocart-total-item">{itemsTotal}</span>
      <SvgIcon logoName={{addToCart: true}} size={{customSize}} baseClassname={"add-to-cart-icon"}/>
    </div>
  )
}

export default AddToCart