import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import { AddToCart, CustomButton, Footer, ModalComponent, ServiceCard, SvgIcon } from '../../components';
import { getAstroData } from '../../store/selectors';
import { servicesData } from './utils';
import { setLoading } from '../../store/slice';
import { URLRoutes } from "../../URLRoutes"
import "./Services.scss";
import PaypalComponent from '../../components/PaypalComponent/PaypalComponent';

function Services({ setTileDescription }) {
  
  const [isTileClick, setIsTileClick] = useState(true);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { cartItems } = useSelector(getAstroData);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {client: {servicesDescription, servicesPayment, services}} = URLRoutes;
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [tileOpen, setTileOpen] = useState(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ block: "start", behaviour: "smooth" });
  })

  // const handleToggleTile = (index) => {
  //   if (tileOpen === index) {
  //     setTileOpen(null);
  //   } else {
  //     setTileOpen(index);
  //   }
  // }

  const handleNavigatePayment = () => {
    navigate(servicesPayment);
    // setIsTileClick(true);
  }

  // const handleNavigateBack = () => {
  //   navigate('/servicestemp')
  //   setIsTileClick(true);
  // }

  const cartSize = useMemo(() => {
    return cartItems?.length;
  }, [cartItems])

  // const isServiceCardPage = useMemo(() => {
  //   return pathname === "/servicestemp";
  // }, [pathname]);

  const isPaymentCheckout = useMemo(() => {
    if (pathname === "/services-payment") {
      return false;
    };
  }, [pathname]);

  // const isServicePaymentPage = useMemo(() => {
  //   return pathname === "/servicestemp/services-payment";
  // }, [pathname]);

  // const isServiceDescriptionPage = useMemo(() => {
  //   return searchParams.get('service') === 'service-details';
  // }, [searchParams]);

  // const isServicePageButton = useMemo(() => {
  //   if (!isTileClick) {
  //     return true;
  //   } else if (isServicePaymentPage) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }, [isServicePaymentPage, isTileClick])

  // const servicesPageTitle = useMemo(() => {
  //   if (isServiceCardPage) {
  //     return "Discover our special services offered"
  //   } else if (isServicePaymentPage) {
  //     return "Payment and Personal Information"
  //   }
  // }, [isServiceCardPage, isServicePaymentPage])

  const cartTotal = useMemo(() => {
    return cartItems?.reduce((accumulator, currentValue) => {
      const price = currentValue.serviceCardPrice.match(/\d+/g); // Extract numbers
      const numericValue = price ? Number(price[0]) : 0; // Convert to number or default to 0
      return accumulator + numericValue;
    }, 0);
  }, [cartItems]);

  const handleDeleteCartItem = (index) => {
    console.log("handleDeleteCartItem", index);
    dispatch({ type: 'REMOVE_FROM_CART', payload: index });
    dispatch(setLoading());
  }

  const renderCartItems = (item, index) => {
    const { serviceName, serviceCardPrice } = item;

    const handleDeleteCartIndex = () => {
      handleDeleteCartItem(index);
    }

    return (
      <div className='service-cart-list' key={`renderCartItem-index-${index}`}>
        <span className='service-list-name'>{serviceName}</span>
        <span className='service-list-price'>{serviceCardPrice}</span>
        <CustomButton
          noBackground
          noPadding
          onClick={handleDeleteCartIndex}
          baseclassname={"service-list-delete"}
        >
          <Icon name='trash alternate' />
        </CustomButton>
      </div>
    )
  }

  const renderServicesTiles = (item, index) => {

    // const isOpen = tileOpen === index;

    // const handleTileOpen = () => {
    //   handleToggleTile(index);
    // }

    const handleNavigateForward = () => {
      // setSearchParams({ 'service': 'service-details' })
      // setIsTileClick(false);
      navigate(servicesDescription);
      setTileDescription({
        ...servicesData[index],
      });
    }

    return (
      <ServiceCard
        {...item}
        isSingleQuestion={index === 0}
        isGoldCard={item.isGoldService}
        index={index}
        handleClick={handleNavigateForward}
      />
    )
  }

  console.log("isPaymentCheckout", isPaymentCheckout);

  return (
    <div ref={ref} className={classNames(["services-container"], {
      "is-services-card-page": isTileClick
    })}>
      {/* <PaypalComponent description={"hello"} totalAmount={1.00}/> */}
      <div className="services-header-container">
        <h1 className='services-page-header-title'>Explore our astrological services</h1>
        <ModalComponent
          modalState={isPaymentCheckout}
          header={"Cart Items"}
          trigger={
            <div style={{ cursor: "pointer" }} className='services-cart-button-container'>
              <AddToCart customSize={"6rem"} itemsTotal={cartSize} />
            </div>
          }
          content={
            <div className='service-cart-container'>
              {cartSize !== 0 &&
                <div className='service-cart-title-container'>
                  <span className='service-cart-title-text'>Service Name</span>
                  <span className='service-cart-title-text'>Service Price</span>
                  <span className='service-cart-title-action '>Actions</span>
                </div>}
              {cartItems?.map(renderCartItems)}
              {cartSize !== 0 ?
                <>
                  <div className='service-card-subtotal-container'>
                    <span className='service-cart-total-text'>Subtotal</span>
                    <span className='service-cart-total-text'>${cartTotal}</span>
                  </div>
                  <div className='service-cart-button-container'>
                    <CustomButton
                      blueTheme
                      onClick={handleNavigatePayment}
                      buttonText={"Checkout and Pay"}
                    />
                  </div>
                </> :
                <>
                  <h1>No Items in the Cart</h1>
                  <h1>Add some items to make a purchase</h1>
                </>}
            </div>
          }
        />
      </div>
      <h3 className='service-book-one-consultation-header'>Book your One-on-one consultation</h3>
      <div className="services-tiles-container">
        {servicesData.map(renderServicesTiles)}
      </div>
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default Services;