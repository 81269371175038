import classNames from "classnames";
import "./TileComponent.scss";

function TileComponent({ 
  baseclassname, 
  noBackground, 
  blueShade,
  blueShade1,
  blueShade2,
  transparentBlueTheme,
  goldBlueTheme,
  goldTransparent,
  noPadding,
  handleClick,
  children, 
  ref
}) {
  return (
    <div ref={ref} className={classNames(["tile-container", baseclassname],{
      "no-background-tile": noBackground,
      "blue-shade": blueShade,
      "blue-shade-1": blueShade1,
      "blue-shade-2": blueShade2,
      "transparent-blue-theme": transparentBlueTheme,
      "gold-blue-theme": goldBlueTheme,
      "gold-transparent-theme": goldTransparent,
      "no-padding": noPadding
    })} onClick={handleClick}>
      {children}
    </div>
  )
}

export default TileComponent;