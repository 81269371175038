import nadiAstrology from "./CardImages/nadiastrology.png";
import numerloggy from "./CardImages/numerology.png";
import palmistry from "./CardImages/palmistry.png";
import vedicCards from "./CardImages/vedic.png";
import auspiciousTiming from "./CardImages/auspicioustiming.png";
import loveCompatibility from "./CardImages/lovecompatibility.png";
import relationshipsMarriage from "./CardImages/relationships&marriage.png"
import healthWelness from"./CardImages/health&witness.png";
import financialPlanning from "./CardImages/financialPlanning.png";
import purchaseSell from "./CardImages/purchaseSell.png";
import litigations from "./CardImages/litigations.png";
import birthRectification from "./CardImages/birthRectification.png";
import gemstonesRemedies from "./CardImages/gemstonesRemedies.png";
import immigrationTravel from "./CardImages/travelImmigration.png";
import horaryAstrology from "./CardImages/horaryAstrology.jpg";
import petAstrology from  "./CardImages/petAstrologists.png";


export const data1 = [
  "In this Era of ancient wisdom and practical approach, we explore the cosmic symphony that shapes your life's path.",
  "Astrology, a timeless art that connects you to the celestial dance of planets and stars;",
];

export const data2 = [
  "Numerology, the mathematical magic that deciphers the hidden codes in your name and birthdate;",
  "Palmistry, where the lines on your hands tell stories of your past, present, and future, come together in perfect harmony.",
];

export const data3 = [
  "It's like holding the keys to your own destiny.",
  "We believe that true self-discovery is a multifaceted journey.",
];

export const data4 = [
  "Indian Astrology",
  "The precision of Numerology",
  "The Art of Palmistry",
];

export const data5 = [
  "We are dedicated to unlocking the mysteries of the cosmos for you.",
  "We are here to decode the intricate layers of your being",
  "We provide you with personalized redaings that empower you to make informed decisions, navigate life's twists and turns, and harness the energies that surround you.",
];

export const skillsData = [
  {
    cardName: "Nadi Astrology",
    cardSubtitle: "",
    cardImage: nadiAstrology,
    cardDesc: "Astrology reading: interpreting celestial positions to gain insights into one's personality, life events, and potential future.",
    cardLink: "",
  },
  {
    cardName: "Numerology",
    cardImage: numerloggy,
    cardDesc: "Numerology reading interprets the significance of numbers in a person's life to uncover insights about their character, destiny, and life path.",
    cardLink: "",
  },
  {
    cardName: "Palm Reading",
    cardImage: palmistry,
    cardDesc: `Palmistry reading is the practice of interpreting the lines, mounts, and shapes of the hand to gain insights into a person's character and future.`,
    cardLink: "",
  },
  {
    cardName: "Vedic Cards",
    cardSubtitle: "(Advance Tarrot)",
    cardImage: vedicCards,
    cardDesc: "Vedic cards reading is kinda advance nadi astrology card reading which can handle all types of astrological questions",
    cardLink: "",
  },
  {
    cardName: "Auspicious Timing",
    cardSubtitle: "(Mahurat)",
    cardImage: auspiciousTiming,
    cardDesc: "Auspicious timing calculation is the art of determining favorable moments based on astrology or cultural beliefs for various activities or events.",
    cardLink: "",
  },
  {
    cardName: "Love Compatibility",
    cardSubtitle: "(Kundli Milan)",
    cardImage: loveCompatibility,
    cardDesc: "Love compatibility readings offer insights into the potential harmony and connection between two individuals' energies and emotions.",
    cardLink: "",
  },
  {
    cardName: "Relationships & Marriage",
    cardImage: relationshipsMarriage,
    cardDesc: "Love and relationship readings offer insights into your love life guidance and clarity for those seeking understanding and direction in their romantic lives.",
    cardLink: "",
  },
  {
    cardName: "Health & Wellness",
    cardImage: healthWelness,
    cardDesc: "Health readings claim to provide insights into an individual's well-being and potential health issues through the scinece of stars.",
    cardLink: "",
  },
  {
    cardName: "Horary Astrology",
    cardSubtitle: "(Prashna Jyotish)",
    cardImage: horaryAstrology,
    cardDesc: "Prashna jyotish is used to provide insights and predictions related to that particular inquiry only. we create a chart for the exact time when a client poses a specific question.",
    cardLink: "",
  },
  {
    cardName: "Financial Planning",
    cardSubtitle: "(Corporate & HR)",
    cardImage: financialPlanning,
    cardDesc: "Career psychic readings provide insights and guidance on your professional path, helping you make informed decisions and achieve your career goals.",
    cardLink: "",
  },
  {
    cardName: "Purchase & Sell",
    cardSubtitle: "(Property, Investments)",
    cardImage: purchaseSell,
    cardDesc: "Investments reading: A speculative approach to financial decisions guided by mystical insights",
    cardLink: "",
  },
  {
    cardName: "Litigations",
    cardSubtitle: "(Family Disputes & Commercial)",
    cardImage: litigations,
    cardDesc: "Litigation reading offers insights into legal matters using psychic abilities and intuitive guidance.",
    cardLink: "",
  },
  {
    cardName: "Birth time rectification",
    cardImage: birthRectification,
    cardDesc: "Birth time rectification is the process of fine-tuning a person's recorded birth time to improve the accuracy of astrological readings.",
    cardLink: "",
  },
  {
    cardName: "Gemstone & Remedies",
    cardImage: gemstonesRemedies,
    cardDesc: "Gemstones are believed to harness unique energies, serving as astrological remedies to enhance specific planetary influences in one's life.",
    cardLink: "",
  },
  {
    cardName: "Immigration & Travel",
    cardImage: immigrationTravel,
    cardDesc: "Travel reading offers insights into your future journeys and helps you make informed decisions about your travel plans.",
    cardLink: "",
  },
  {
    cardName: "Pet Astrology",
    cardImage: petAstrology,
    cardDesc: "In pet astrology, predictions regarding pet lost and found, behavior, anxiety  and even health are possible.",
    cardLink: "",
  },
];
