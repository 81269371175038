import classNames from 'classnames';
import './InputComponent.scss';

function InputComponent({
  paymentStyle,
  contactInputStyle,
  transparentBlueTheme,
  baseclassname,
  label,
  onChange,
  inputType,
  name,
  value,
  errors,
  touched
}) {
  return (
    <div className={classNames([baseclassname], {
      'input-container': contactInputStyle,
      'paymentform-input': paymentStyle,
      'input-transparent-blue-theme': transparentBlueTheme
    })}>
      <div className='input-area'>
        <span className='input-label'>{label}</span>
        {inputType !== 'areabox' && <input name={name} value={value} onChange={onChange} type={inputType} className='input-box' />}
        {inputType === 'areabox' && <textarea name={name} value={value} onChange={onChange} rows={"5"} className='input-textarea' />}
      </div>
      {errors && touched && <span className='formik-input-errors'>{errors}</span>}
    </div>
  )
}

export default InputComponent