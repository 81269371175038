import './Menubar.scss';
import { NavLink } from 'react-router-dom';

function Menubar({
  headersLinks,
  position,
  showSideBar,
  handleToggleMenu
}) {

  const isSidebar = position === 'sidebar';
  const isHeader = position === 'header';

  const renderHeaderMenus = ({ name, route }, index) => {
    return (
      <NavLink
        key={`renderHeadermenu-index${index}`}
        className={"header-route"}
        to={route}
        onClick={handleToggleMenu}
      >
        {name}
      </NavLink>
    )
  }

  return (
    <>
      {isSidebar && showSideBar &&
        <div className='sidebar-container'>
          <div className="sidebar">
            {headersLinks.map(renderHeaderMenus)}
          </div>
        </div>
      }
      {isHeader &&
        <div className={"header-route-container"}>
          {headersLinks.map(renderHeaderMenus)}
        </div>
      }
    </>
  )
}

export default Menubar