import classNames from 'classnames';
import CustomButton from "../CustomButton/CustomButton";
import TileComponent from '../TileComponent/TileComponent';
import SvgIcon from '../SvgIcon/SvgIcon';
import './ServiceCard.scss';

function ServiceCard({
  serviceTitle,
  serviceImage,
  servicePrice,
  serviceSubtitle,
  serviceListTitle,
  serviceList,
  isSingleQuestion,
  isGoldCard,
  index,
  handleClick,
}) {



  return (
    <TileComponent noPadding blueShade2={!isGoldCard} goldBlueTheme={isGoldCard} baseclassname={classNames(['servicecard-container'], {
      "gold-card-theme": isGoldCard
    })}>
      <div className="servicecard-image-container" style={{backgroundImage: `url(${serviceImage})`}}>
        <div className="servicecard-title-container">
          <div className="servicecard-title-pill">{serviceTitle}</div>
        </div>
        <div className="servicecard-price-container">
          <TileComponent transparentBlueTheme baseclassname={"servicecard-price-tile"}>{servicePrice}</TileComponent>
        </div>
      </div>
      <div className="servicecard-button-container">
        <TileComponent goldBlueTheme={isGoldCard} noPadding baseclassname={"servicecard-subtitle-tile"}>
          <span className='servicecard-subtitle-text'>{serviceSubtitle}</span>
        </TileComponent>
        <CustomButton round blueShade1={!isGoldCard} goldBlueTheme={isGoldCard} baseclassname={"servicecard-button"} onClick={handleClick}>
          <div className='servicecard-button-text'>Read More</div>
        </CustomButton>
      </div>
    </TileComponent>
  )
}

export default ServiceCard;