export const martialOptions = [
  {
    text: "Select any option",
    value: "select_any_option"
  },
  {
    text: "Single",
    value: "single"
  },
  {
    text: "Married",
    value: "married"
  },
  {
    text: "Divorced",
    value: "divorced"
  },
]

export const genderOptions = [
  {
    text: "Select any option",
    value: "select_any_option"
  },
  {
    text: "Male",
    value: "male"
  },
  {
    text: "Female",
    value: "female"
  },
  {
    text: "Prefer not to say",
    value: "prefer_not_to_say"
  },
]