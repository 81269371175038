import { BusinessInquiries } from "./ContactAnswers";

export const contactFaq = [
  {
    faqTitle: "Customer Support",
    faqAnswer:
      "Our dedicated customer support team is available to assist you with any inquiries or concerns you may have. Whether you're looking for product information, need assistance with an order, or simply want to provide feedback, our team is here to help.",
  },
  {
    faqTitle: "Business Inquiries",
    faqAnswer: <BusinessInquiries />,
  },
  {
    faqTitle: "Customer Feedback",
    faqAnswer: "We value your feedback as it helps us continually improve our services. Feel free to share your thoughts, suggestions, or any issues you've encountered. We're committed to making your experience better.",
  },
];
