import { useMemo } from "react";
import { Icon } from "semantic-ui-react";
import ModalComponent from "../ModalComponent/ModalComponent";
import { footerPolicyMenus, footerSocialLinkIcons } from "./utils";
import "./Footer.scss";

const date = new Date();
function Footer() {

  const year = useMemo(() => {
    return date.getFullYear();
  }, [])

  const renderFooterSocialLinks = (item, index) => {
    const { linkIcon, linkUrl } = item;
    console.log(item);
    return (
      <a
        className="footer-social-link"
        key={`renderFooterSocialLinks-index-${index}`}
        href={linkUrl}
        target="_blank"
        rel="noreferrer"
      >
        <Icon className="social-link-icon" name={linkIcon} />
      </a>
    )
  }

  const renderFooterPolicyMenu = (item, index) => {
    const { text, content } = item;
    return (
      <ModalComponent
        header={text}
        trigger={
          <a
            key={`renderFooterPolicyMenu-index-${index}`}
            className="policy-menu"
            href="#footer"
          >
            {text}
          </a>
        }
        content={content}
      />
    )
  }

  return (
    <div className="footer-container">
      <div className="footer-area">
        <div className="footer-social-links">
          <span className="follow-social-title">Follow us on </span>
          <div className="footer-links">
            {footerSocialLinkIcons.map(renderFooterSocialLinks)}
          </div>
        </div>
        <div className="footer-section">
          <span className="copyright-content">&copy; {year} All rights reserved</span>
          <div className="footer-policyMenus">
            {footerPolicyMenus.map(renderFooterPolicyMenu)}
          </div>
        </div>
        <div className="footer-developers-contact">
          <span>Made by <a target="_blank" href="https://www.linkedin.com/company/devpeak-innovations/">DevPeak Innovations</a></span>
          <span>Developed by <a target="_blank" href="http://dhanur350.github.io/My_Portfolio">Dhanur</a></span>
        </div>
      </div>
    </div>
  )
}

export default Footer