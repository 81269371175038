import { createSlice } from "@reduxjs/toolkit";
import {
  addToCartReducer,
  removeFromCartReducer,
  resetIsPaymentDoneR,
  // setDataReducers,
  sendEmailReducers,
  sendServicePaymentFormR,
  setIsPaymentDoneR,
  setLoadingReducers,
  singleQuestionReducer,
} from "../reducer";

export const astrologicalSlice = createSlice({
  name: "astrologicalSlice",
  initialState: {
    message: "",
    isLoading: false,
    cartItems: [],
    servicesCustomerData: {},
    singleQuestionData: {
      question: "",
      servicePrice: "",
    },
    isPaymentDone: false
  },
  reducers: {
    // setData: setDataReducers,
    sendServicePaymentForm: sendServicePaymentFormR,
    sendEmail: sendEmailReducers,
    setLoading: setLoadingReducers,
    addToCart: addToCartReducer,
    setIsPaymentDone: setIsPaymentDoneR,
    resetIsPaymentDone: resetIsPaymentDoneR,
    removeFromCart: removeFromCartReducer,
    singleQuestionInfo: singleQuestionReducer,
  },
});

export const { sendEmail, setLoading, setIsPaymentDone, resetIsPaymentDone } = astrologicalSlice.actions;
export default astrologicalSlice.reducer;
