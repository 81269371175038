// export function setDataReducers(state, action) {
//   state.data = action.payload;
//   state.isLoading = false;
// }

export function sendServicePaymentFormR(state, action) {
  console.log("action service", action.payload);
  state.servicesCustomerData = action.payload;
  state.isLoading = false;
}

export function sendEmailReducers(state, action) {
  console.log("action", action.payload.message);
  state.message = action.payload.message;
  state.isLoading = false;
}

export function addToCartReducer(state, action) {
  console.log("action", action.payload);
  // state.cartItems?.push(action.payload);
  const isItemInCart = state.cartItems?.some(item => 
    item.serviceName === action.payload.serviceName && 
    item.serviceCardPrice === action.payload.serviceCardPrice
  );
  
  if (!isItemInCart) {
    state.cartItems?.push(action.payload);
  }
  state.isLoading = false;
  state.isPaymentDone = false;
}

export function removeFromCartReducer(state, action) {
  console.log("action", action.payload);
  const filteredCart = [...state.cartItems.slice(0, action.payload), ...state.cartItems.slice(action.payload + 1)]
  console.log("filteredCart", filteredCart);
  state.cartItems = filteredCart;
  state.isLoading = false;
  state.isPaymentDone = false;
}

export function singleQuestionReducer(state, action) {
  console.log("action", action.payload);
  state.singleQuestionData = action.payload;
  state.isLoading = false;
}

export function setIsPaymentDoneR(state, action) {
  state.isPaymentDone = true;
}

export function resetIsPaymentDoneR(state, action) {
  state.isPaymentDone = false;
}

export function setLoadingReducers(state) {
  state.isLoading = true;
}
