import { Button } from 'semantic-ui-react'
import classNames from 'classnames'
import './CustomButton.scss';

function CustomButton({
  baseclassname,
  disabled,
  loading,
  pillShape,
  round,
  defaultButton,
  blueTheme,
  blueShade1,
  goldBlueTheme,
  halfPadding,
  noBackground,
  noPadding,
  buttonText,
  textclassname,
  children,
  ref,
  onClick,
  buttonTitle,
  type
}) {
  return (
    <div className={classNames(["custom-button-container", baseclassname],{
       "pill-shape" : pillShape,
       "round-shape" : round,
       "default-button" : defaultButton,
       "blue-theme": blueTheme,
       "blue-shade-1": blueShade1,
       "gold-blue-theme": goldBlueTheme,
       "no-padding-button": noPadding,
       "half-padding": halfPadding,
       "no-background": noBackground,
    })} title={buttonTitle}>
      <Button
        className='custom-button'
        disabled={disabled}
        loading={loading}
        onClick={onClick}
        type={type}
        ref={ref}
      >
        {buttonText && <div className={textclassname}>{buttonText}</div>}
        {children}
      </Button>
    </div>
  )
}

export default CustomButton;