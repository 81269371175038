import singleQuestion from "../../assets/cardImages/single-question.jpg";
import astroNumero from "../../assets/cardImages/astro-numero.png";
import matchMaking from "../../assets/cardImages/kundli-milan.jpg";
import cardReading from "../../assets/cardImages/card-reading.png";
import auspiciousTiming from "../../assets/cardImages/auspicious-timing.png";
import marriageRelationship from "../../assets/cardImages/marriage.jpg";
import childBirthAnalysis from "../../assets/cardImages/child-birth-analysis.png";
import education from "../../assets/cardImages/education.png";
import careerFinance from "../../assets/cardImages/career-finance.png";
import cooperateConsultation from "../../assets/cardImages/cooperate-consulation.png";
import healthWellness from "../../assets/cardImages/health.png";
import travelImmigration from "../../assets/cardImages/travel-immigration.png";
import propertyVehicle from "../../assets/cardImages/property-vehicle.png";
import nameAnalysis from "../../assets/cardImages/name-analysis.png";
import litigations from "../../assets/cardImages/litigation.png";
import birthTimeRectification from "../../assets/cardImages/birth-time-rectification.png";
import gemstoneRemedies from "../../assets/cardImages/gem-stones.png";
import palmReading from "../../assets/cardImages/palmistry.jpg";
import petAstrology from "../../assets/cardImages/pet-astrology.png";
import miscQueries from "../../assets/cardImages/misc-queries.png";

export const servicesData = [
  {
    index: 0,
    serviceTitle: "Single Event Question",
    servicePrice: "$95",
    serviceSubtitle:
      "Explore cosmic mysteries with tailored answers, as stars illuminate the path, one event at a time.",
    serviceListTitle: "Question you can ask about!",
    serviceImage: singleQuestion,
    // serviceList: [
    //   "Relationships and Marriage",
    //   "Education",
    //   "Property",
    //   "Finance",
    //   "Health",
    //   "Travel and Immigration",
    //   "Litigation",
    //   "Career",
    // ],
    isSingleQuestion: true,
    singleQuestions: [
      {
        category: "Ask question about children?",
        questionList: [
          "When will I have child?",
          "Who will get curtody of child?",
          "Shall we adopt a child?",
          "Will conception be normal or require IVF?",
          `What ‘s the best period for conception?`,
        ],
      },
      {
        category: "Ask question about Education?",
        questionList: [
          "Which line of education is best suited for me?",
          "Will I be able to clear competitive examination?",
          "Will I able to go to foreign for study?",
          "Will I able to clear emamination successfully?",
          "Can I secure admission in my desired institution?",
        ],
      },
      {
        category: "Ask question about Health?",
        questionList: [
          "Can surgery be avoided?",
          "When will I able to fully recover from the disease ?",
          "Will the treatment of this doctor will be beneficial for my health ?",
          "Which is better: homeopathy/allopathy/ayurveda ?",
        ],
      },
      {
        category: "Ask question about property/vehicle?",
        questionList: [
          "When will I be able to purchase new property/vehicle?",
          "When will I able to get loan for property/vehicle?",
          "When can I secure a loan for property/vehicle?",
          "When can I sell property/vehicle for profit?",
          "Will I get parental property?",
          "Whether the property/vehicle is lucky for me or not?",
        ],
      },
      {
        category: "Ask question about travel?",
        questionList: [
          "When will I able to traval in foreign?",
          "Will I be able to get job in foreign country?",
          "Will I get visa?",
          "Will I able to settle abroad?",
          "Can I pursue higher education abroad?",
        ],
      },
      {
        category: "Ask question about Litigation?",
        questionList: [
          "Will I get bail or not?",
          "Will I win the ourt case or not?",
          "Will my court case settle amicably?",
          "Will I be imprisoned/arrested?",
          "Mediation or pursue the case further!",
        ],
      },
      {
        category: "Ask question about Love?",
        questionList: [
          "He/She loves me or not?",
          "Will my relationship be long lasting?",
          "Will I be able to marry the person I love?",
          "Will I have love marriage or arranged marriage?",
          "Will my live-in relationship be smooth?",
          "When will I find my soul mate?",
          "When will my differences with my lover come to an end?",
          "How long will be my relationship last?",
          "Does he/she truly loves me or are they pretending?",
        ],
      },
      {
        category: "Ask question about Marriage?",
        questionList: [
          "When will I get married?",
          "Will I marry a foreigner or not?",
          "Will I marry the person I love?",
          "Will my life partner love me?",
          "My life partner is committed to me or not?",
          "Will I have a good married life?",
          "When will I get divorce?",
          "When will I remarry?",
          "Can I resolve issues with my partner?",
          "Will I be able to get custody of my child?",
          "When will I reconcile with my partner?",
          "Will I have love marriage or arranged marriage in the future?",
        ],
      },
      {
        category: "Ask question about Finance?",
        questionList: [
          "When will I be able to pay back loan?",
          "Shall I take the loan or not?",
          "When will I able to recover my money?",
          "Will I get parental property?",
          "When will I become a millionare or billionare?",
          "When will I be able to earn more money?",
          "Will friends/relatives provide financial help?",
          "Will investments in shares/property be profitable?",
          "Will I be able to purchase the property?",
          "Will I get loan on property?",
          "Will I able to purchase the car I desire?",
          "Will I get loan to purchase property?",
          "Can I recover a loan I give?",
        ],
      },
      {
        category: "Ask question about Career?",
        questionList: [
          "When will I get a new job?",
          "When will I be transferred to my hometown?",
          "When will I get job?",
          "Whether new job will be better for me?",
          "When will I get promotion?",
          "Shall I wait for promotion in this job or change the job?",
          "Shall I do business or job?",
          "Should I switch from job to business?",
          "Does partnership suits to me or not?",
          "Shall I do partnership with him/her?",
          "When will I get job in overseas?",
          "Which profession is good for me?",
          "Will I get a Govt. Job?",
          "Will I become IAS/IPS/IRS officer?",
          "When my business will run smoothly?",
        ],
      },
    ],
    servicesDescription: [
      `If you are currently caught in the horns of a dilemma, feeling worried, and perhaps a bit lost? 
      Is there something beyond your regular critical sense that's causing confusion? 
      And  wondering if the stars can offer a solution to your perplexities? Need cosmic guidelines? If so! Our Single Event question service is the best way to get a clean and clear picture about your curiosity.`,

      "This nadi astrology method is used to provide insights and predictions related to one particular inquiry only. Prashna Kundli/ Horary chart is particularly useful when a person doesn't have a full birth chart available or wants to address a specific concern or decision only. ",

      "Our technique of Horary astrology is way more different and highly accurate in terms of predictions.The astrologer analyzes the planetary positions, houses, and aspects at the moment of the question to derive answers and guidance. All we need is your question and nothing else.",
    ],
  },
  {
    index: 1,
    serviceTitle: "In-Depth Kundli Analysis",
    servicePrice: "$250",
    isGoldService: true,
    serviceImage: astroNumero,
    serviceSubtitle:
      "Discover life's journey through digits, unraveling the cosmic code that defines your unique destiny.",
    servicesDescription: [
      "Horoscope analysis is a personalized examination of an individual's astrological birth chart, which is a snapshot of the celestial positions at the time of their birth. This in-depth scrutiny involves interpreting astrological points to gain insights into various aspects of life, such as personality traits, relationships, career, and potential challenges.",
      "This offer a comprehensive understanding of an individual's strengths, weaknesses, opportunities, and potential life path. Horoscope analysis provides a roadmap for self-discovery, helping individuals make informed decisions, navigate challenges, and align their actions with cosmic energies for a more fulfilling life journey. It's a tool that blends ancient wisdom with modern insights to explore the intricate interplay between celestial forces and personal destiny.",
      "We also include the timings of the above events. For instance Education, Litigation, Property & Vehicles, Health, Travel, Financial & Career Prospects, Marriage, Children, Longevity, Personalities, Remedial Measures. Numerology reading is key to know the nature and hidden traits of a personality– Anxiety, depression, sentimental, drug addict, Frustrated, Overconfident, Miser, Spendthrift, mismanaging funds, brand cautious etc..",
      "All we need is your birth date (25-Jan-2015), exact time of birth (24:00) and exact place of birth. In case of any missing information regarding birth, we can also provide reading and predictions with the blend of prashna jyotish and vedic cards",
    ],
  },
  {
    index: 2,
    serviceTitle: "Kundli Milan",
    servicePrice: "$196",
    isGoldService: true,
    serviceImage: matchMaking,
    serviceSubtitle:
      "Celestial matchmaker aligns hearts, weaving a cosmic love story as planets dance in harmony.",
    servicesDescription: [
      "Curious about your cosmic love compatibility? Ever wondered if your stars align with your partner's? Is the mystical dance of celestial bodies weaving a tapestry of love for you?",
      "Kundali Milan or Horoscope matching stands as a beacon of hope and guidance. Our approach to this ancient art is deeply rooted in scientific principles, transcending the traditional realms of Gun Milan and Manglik considerations. We believe in a holistic analysis, focusing on critical aspects that form the foundation of a happy, lifelong partnership.",
      "Compatibility in nature and a harmonious family life are key considerations, aligning the couple's aspirations with celestial blessings for childbearing.Character and personality play a pivotal role, laying the foundation for mutual respect. We delve into professional success and financial stability, acknowledging their role in creating a secure environment. Our approach to Kundali Milan is a fusion of old and new, blending traditional wisdom with contemporary insight to guide couples toward a joyful and prosperous married life.",
      "This Kundli-Milan or Match-Making Diagnostic Report will be a base for your Selection / Decision Making. Hence, Nadi Match-Making is a trusting process.",
    ],
  },
  {
    index: 3,
    serviceTitle: "Vedic Card Reading",
    serviceImage: cardReading,
    servicePrice: "$160",
    serviceSubtitle:
      "Vedic cards unfold life's tapestry, revealing the intricate patterns of fate in cosmic design.",
    servicesDescription: [
      "Seeking love, prosperity, or financial abundance? Eager to turn your aspirations into reality but tired of encountering delays? Following a session with us, Nadi cards will not only furnish clear answers to all your queries but also offer guidance to steer you in the right direction, ultimately leading to success.",
      "Nadi cards, rooted in the principles of Nadi Astrology, are often referred to as Vedic cards. Capable of addressing both Yes/No questions and providing in-depth insights into your current situation, path, and potential outcomes, a Nadi card reading promises to bring satisfaction and clarity. Treat yourself to this enlightening experience and open the door to positive transformations in your life!",
    ],
  },
  {
    index: 4,
    serviceTitle: "Shubh Muhurat",
    servicePrice: "$160",
    serviceImage: auspiciousTiming,
    serviceSubtitle:
      "Embark on success journeys as stars align, guiding ventures to prosperity with perfect cosmic timing.",
    servicesDescription: [
      "Discover the magic of 'Shubh Muhurat' in astrology- those golden moments when the universe aligns perfectly. Picture a time where planets hum in harmony, creating an auspicious window for a perfect start to your ventures. Each moment holds unique significance; let 'Shubh Muhurat' guide you to the stars' blessings for a truly special beginning. ",

      "Marriage Celebation, Buying a house / Vehicle, Registry of house / Commercial property, Buying a land / Bhoomi Poojan, Child Mundan Sankar, Shop / Godown / Factory Opening Celebrations",

      "Applying for any Visa / Applying for higher education degree, Health related matters – When to opt for Surgery / Procedures and other important matters.",

      "When choosing the perfect Muhurat, it's not just about the day—it's all about the specific time. Our job is to carefully calculate and give you that crucial timeframe, those exact minutes when you should kick off your planned activity. This way, your efforts sync up with the most favorable cosmic vibes, ensuring success in your endeavors.",
    ],
  },
  {
    index: 5,
    serviceTitle: "Marriage & Relationships",
    servicePrice: "$196",
    serviceImage: marriageRelationship,
    serviceSubtitle:
      "Journey into marital bliss with cosmic insights; let the planets narrate your union's celestial tale.",
    servicesDescription: [
      "Embark on a cosmic exploration of your romantic journey with our Marriage, Love Life, and Relationship Astrology Report. Imagine the universe crafting a unique love story for you, where the alignment of stars during your birth reveals profound insights into your marital bliss and relationships.",

      "Uncover the celestial secrets that shape your love life, providing personalized guidance on compatibility, potential challenges, and the ideal times for romantic endeavors. Much like constellations in the night sky, astrology unveils the dynamics of your relationships, offering clarity on your path to enduring love.",

      `Our unique astro approach, lets us go deep in your horoscopes to know your marital life cycle.
      For example, discover how planetary positions influence communication in relationships, or how certain celestial alignments may indicate periods of harmony or potential challenges. Astrology empowers you to navigate the cosmic currents of love, ensuring your journey is guided by the stars.`,

      `Accurate birth details are crucial for precise insights. Let the celestial bodies guide you to harmonious bonds and enduring love with our Marriage, Love Life, and Relationship Astrology Report.`,
    ],
  },

  {
    index: 6,
    serviceTitle: "Child Birth Analysis",
    servicePrice: "$160",
    serviceImage: childBirthAnalysis,
    serviceSubtitle:
      "Celestial cradle tales unfold; planets guide the path of new life, unveiling cosmic mysteries",
    servicesDescription: [
      "Explore the cosmic realms of anticipation and joy with our Child Birth Analysis Astrology Report. Picture a celestial tapestry woven by the alignment of stars during your birth, revealing insights into the potential journey of bringing new life into the world.",

      "Delve into personalized astrological analyses that offer guidance on auspicious times for conception, potential challenges during pregnancy, and the overall cosmic landscape surrounding childbirth. Much like constellations guiding sailors at sea, astrology can illuminate the path to a smooth and joyous entry into parenthood.",

      "Consider the example of how planetary influences may indicate ideal periods for conception or potential phases that require extra care. Accurate birth details are paramount for precision in our insights. Let the stars guide you through the celestial dance of fertility and parenthood with our Child Birth Analysis Astrology Report.",
    ],
  },
  {
    index: 7,
    serviceTitle: "Education",
    servicePrice: "$160",
    serviceImage: education,
    serviceSubtitle:
      "Follow stellar blueprints for learning; let astrology guide your educational odyssey with cosmic wisdom.",
    servicesDescription: [
      `Wondering which domain of education will suit you best? Or may be for your son / daughter?
      Wabt to know, In what sense stars and planets are able to support your education and future?
      Do you face challenges in your educational journey? Wondering about the best paths for learning and success? `,

      `Wondering which domain of education will suit you best? Or may be for your son / daughter?
      Wabt to know, In what sense stars and planets are able to support your education and future?
      Do you face challenges in your educational journey? Wondering about the best paths for learning and success?`,

      "Our Educational Astrology Report uses celestial insights to guide your academic endeavors. Discover personalized strategies to excel in studies, navigate learning paths, and unlock your educational potential. We analyze the alignment of planets during your birth to reveal cosmic blueprints for learning. Gain insights into the most suitable subjects, study methods, and optimal periods for exams. Astrological guidance can empower you to make informed decisions about your educational pursuits and enhance your overall academic performance.",

      "Accurate birth details ensure precise recommendations. Let the stars illuminate your path to academic success with our Educational Astrology Report.",
    ],
  },
  {
    index: 8,
    serviceTitle: "Career & Finance",
    servicePrice: "$160",
    serviceImage: careerFinance,
    serviceSubtitle:
      "Navigate success with a planetary prosperity map, as cosmic influences shape your career and financial destiny.",
    servicesDescription: [
      "Curious about your career path or financial success? Unlock the cosmic insights with our Career and Finance Astrology Report. Navigate the celestial roadmap to prosperity and make informed decisions about your professional journey and financial goals.",

      "Embark on a celestial exploration of your professional journey and financial success with our comprehensive Career and Finance Astrology Report. Imagine having a roadmap crafted by the alignment of stars during your birth, unveiling insights that shape your path to prosperity.",

      "Delve into personalized strategies tailored to your astrological profile, providing clarity on the ideal career paths and financial opportunities. Just as the planets influence the tides, astrology can guide you through pivotal career decisions. For instance, discover the optimal periods for job changes, strategic investment windows, and potential windfalls in your financial journey.",

      "Astrology empowers you to harness celestial energies, aligning your actions with the cosmic currents for sustained career growth and financial stability. Accurate birth details are essential for the precision of our insights. Let the stars illuminate the way to a fulfilling and prosperous career, guiding your financial endeavors with our detailed Career and Finance Astrology Report.",
    ],
  },
  {
    index: 9,
    serviceTitle: "Corporate Consultation",
    servicePrice: "$250",
    isGoldService: true,
    serviceImage: cooperateConsultation,
    serviceSubtitle:
      "Illuminate business triumphs with astro insights; let the stars guide strategic decisions for corporate prosperity.",
    servicesDescription: [
      "In the dynamic world of business, companies are turning to astrology for insights into ups and downs, expansion decisions, hiring or firing choices, and client retention strategies. Imagine a corporation as a collective entity, with a life and personality shaped by key players' energies, from the CEO to department heads. By understanding their astrological blueprints, we gain valuable insights into operational efficiency, cultural ethos, and growth potential.",

      "Our approach goes beyond just placing talent; we believe in the celestial synergy between personal astrological signatures and corporate roles. Whether it's aligning the mission of a new venture with the stars or identifying the right individuals to navigate financial challenges, we understand that the success of a business is intricately tied to the astrological compatibility of its leaders.",

      "In cases without individual horoscopes, we adeptly turn to 'Prashna Charts,' offering a reliable snapshot of the cosmic climate. Recognizing the corporate body's enduring essence, influenced by celestial imprints, we appreciate its resilience to individual changes",

      "How do we support businesses? We provide meticulous advice on aligning business endeavors with the cosmic rhythm of promoters and ensuring that each departmental head's astrological profile complements the overall objectives of the corporation. Our goal is to weave astrology into the fabric of corporate governance, catalyzing growth and propelling businesses toward success under the auspices of the stars.",

      "Our consultancy not only offers strategic guidance to improve underperforming businesses but also provides tailored remedies to navigate challenging phases and amplify the benefits of favorable periods. With our expertise, we aim to position your enterprise for success in the ever-changing cosmic dance of business.",
    ],
  },
  {
    index: 10,
    serviceTitle: "Health & Wellness",
    servicePrice: "$160",
    serviceImage: healthWellness,
    serviceSubtitle:
      "Find well-being in cosmic guidance; discover a healthier life with astrology's cosmic blueprint for wellness.",
    servicesDescription: [
      "Have you ever experienced severe depression or struggled with mental health issues such as anxiety or hallucinations? Are you curious to understand if the combination of various astrological houses and planets in your horoscope may be exacerbating these challenges? Can astrology provide insights that help you anticipate future health scenarios?",

      "Our Medical Astrology Analysis Report offers such astrological insights through the practice of Nadi Jyotish. You can anticipate receiving personalized, strategic guidance that empowers you to address health challenges, including mental health issues like anxiety, tendencies toward suicidal thoughts, and restlessness of the mind. We also explore potential health problems and how detrimental food habits may be affecting your overall wellness.",

      "To fully harness the potential of Medicinal Astrology, accuracy in providing your birth date, time, and place is imperative. We aim to guide you in fortifying your well-being, offering a range of remedial measures such as gemstones, mantras, puja, Yantras, and Reiki. Take a step towards enhancing your health and well-being by exploring the insights offered through our Medical Astrology Analysis Report.",
    ],
  },
  {
    index: 11,
    serviceTitle: "Travel & Immigrations",
    servicePrice: "$196",
    isGoldService: true,
    serviceImage: travelImmigration,
    serviceSubtitle:
      "Navigate journeys with celestial maps; let stars guide your travel and immigration ventures with cosmic wisdom.",
    servicesDescription: [
      `Are you seeking detailed and accurate predictions for your upcoming travels, be it settling in a foreign land, studying abroad, or returning to your home-land? Curious about the auspicious timings and purposes behind your travel endeavors?
      Our astrology advice can assist you to know future travels, visa application, PR and passport, coming back to home-land, higher education on different foreign land, major pilgrimage plans and more.`,
    ],
  },

  {
    index: 12,
    serviceTitle: "Property & Vehicle",
    servicePrice: "$160",
    serviceImage: propertyVehicle,
    serviceSubtitle:
      "Decipher celestial ownership wisdom; unveil the destiny in property and vehicles with cosmic insights.",
    servicesDescription: [
      `Have you been wondering for astrological advice in regards to property/vehicle purchase or sale
      Curious about the cosmic influence on your property ventures? Wondering if the stars align for your next real estate move? Or other assets investments`,
      `Nadi Astrology provides a personalized approach, guiding you towards the most auspicious times to invest in real estate. Our astrological insights go beyond conventional wisdom, offering a deeper understanding of the cosmic energies for assets acquisition.`,
      `Our analysis report provide details such as Timings of Purchase, Financial risk, Suitable colour of Vehicle to be purchased, parents invesntments, group investment analysis and more.`,
      `Receive detailed insights and recommendations specific to your property investment goals, empowering you to make informed decisions aligned with celestial energies.`,
    ],
  },

  {
    index: 13,
    serviceTitle: "Name Selection Analysis",
    servicePrice: "$196",
    serviceImage: nameAnalysis,
    isGoldService: true,
    serviceSubtitle:
      "Cosmic code in your name; unlock insights into your unique essence with astrology's celestial revelations.",
    servicesDescription: [
      `Are you about to start a new business? Wondering if the name you choosed is alginning to your horoscope and numbers?  Interested in exploring name numerology? Want to consider planets, zodiac signs and alignment of numbers with your upcoming success?`,
      `Name numerology is often consulted for insights into personal and professional aspects of life. It is believed that aligning a person's name with favorable numbers can bring positive energy and success.Different numbers are believed to have distinct characteristics and vibrations that influence aspects of an individual's personality, behavior, and business success.`,
    ],
  },

  {
    index: 14,
    serviceTitle: "Legal / Court Case",
    servicePrice: "$160",
    serviceImage: litigations,
    serviceSubtitle:
      "Seek astro legal insights; navigate cosmic courts for favorable verdicts, unveiling celestial paths to justice.",
    servicesDescription: [
      `Are you about to start a new business? Wondering if the name you choosed is alginning to your horoscope and numbers?  Interested in exploring name numerology? Want to consider planets, zodiac signs and alignment of numbers with your upcoming success?`,
      `Name numerology is often consulted for insights into personal and professional aspects of life. It is believed that aligning a person's name with favorable numbers can bring positive energy and success.Different numbers are believed to have distinct characteristics and vibrations that influence aspects of an individual's personality, behavior, and business success.`,
    ],
  },

  {
    index: 15,
    serviceTitle: "Birth Time Rectification",
    servicePrice: "$196",
    serviceImage: birthTimeRectification,
    serviceSubtitle:
      "Fine-tune the cosmic clock for accuracy; let the stars reveal your true astrological portrait with precision.",
    servicesDescription: [
      `Ever wondered if the time of your birth holds the key to unlocking the true potential of your astrological chart? Enter Birth Time Rectification (BTR), a fascinating process that delves into the intricacies of your birth time to ensure accurate astrological predictions.`,

      `In the realm of astrology, the precision of predictions relies heavily on the accuracy of the birth chart. Birth Time Rectification becomes indispensable when the birth time is uncertain or recorded inaccurately, as even a small deviation can significantly impact the interpretation of planetary positions and their influences on various aspects of life.`,

      `Astrologers employ various technicalities, such as planetary transits, progressions, and solar arcs, along with predictive techniques like event analysis, to fine-tune the birth time. By meticulously comparing the events and milestones in an individual's life with the astrological indicators, practitioners can effectively rectify the birth time, creating a more accurate astrological chart. BTR is a confluence of art and technique, where we take into account a spectrum of pivotal experiences provided by you — from career transitions, academic achievements, and travel, to personal milestones such as engagements and other emotionally charged events.`,

      `This tailored approach ensures that the guidance you receive is not just a general interpretation but a personal narrative that aligns with the celestial forces at play during your most momentous life events.`,
    ],
  },

  {
    index: 16,
    serviceTitle: "Gemstones & Remedies",
    servicePrice: "$160",
    serviceImage: gemstoneRemedies,
    serviceSubtitle:
      "Harmonize cosmic energies with celestial cures found in gemstones, promoting well-being and balance.",
    servicesDescription: [
      `Wondering if gemstone’s cosmic connection could aid you to achieve new heights in life? How ancient mantars could really provide us inner peace and Curious about ancient arts like Reiki and the cosmic guidance of astrology. Wondering how gemstones, mantras, and sacred journeys can light up your way?`,

      `If storms like career setbacks, health concerns, or legal battles are looming, do you need a guiding hand? Ready to share your story and explore bespoke remedies crafted by compassionate healers and wise astrologers? Seeking relief and resolutions aligned with the universe's benevolent forces? Interested in personalized guidance, gemstone recommendations, or rituals to channel positive energies? Explore with us, and let's align your path with the universe's wisdom and serenity`,

      `However, it's important to remember that the source of every comforting gesture and piece of guidance is the inherent grace of the divine. The bountiful fruits of success are reaped with the profound endorsement of the universe. Embracing this sense of interconnectedness with the cosmos, we align ourselves with you, prepared to channel the positive energies and wisdom that the universe provides.
      `,
    ],
    servicePoetLines: [
      "“ਹੁਕਮਿ ਰਜਾਈ ਚਲਣਾ ਨਾਨਕ ਲਿਖਿਆ ਨਾਲਿ”",
      `“Hukam Rajaaee Chalanaa Naanak Likhiaa Naal”`,
      `O Nanak, it is written that you shall obey the Hukam of His Command, and walk in the Way of His Will- Jap Ji Sahib, Shri Guru Nanak Dev Ji
      We reap what we sow.`,
    ],

    //todo add this poet line
    /**
     *
     */
  },

  {
    index: 17,
    serviceTitle: "Palm Reading",
    servicePrice: "$250",
    isGoldService: true,
    serviceImage: palmReading,
    serviceSubtitle:
      "Decode life's secrets in your palms; lines whisper stories, unveiling cosmic destiny in your hands.",
    servicesDescription: [
      `Palm reading is one of the most intriguing paths to self-discovery. Unlock the secrets of your past, present, and future through the art of palmistry. Let your hands reveal the story of your life. Out of all predictive sciences, only palmistry is one which deals closest to life Karma.`,

      `Palm reading, also known as chiromancy or palmistry, is an ancient practice that involves interpreting the lines, mounts, and shapes on the palm of a person's hand to gain insights into various aspects of their life`,

      `It is based on the belief that the hands can reveal information about an individual's character, personality, life experiences, and potential future. Palmistry helps us to know hidden traits of life which are not easily visible in other sciences. All we need is neat and clean pictures of your hands.
      `,
    ],
  },

  {
    index: 18,
    serviceTitle: "Pet Astrology",
    servicePrice: "$95",
    serviceImage: petAstrology,
    serviceSubtitle:
      "Uncover the celestial influences on your pet's life, decoding their unique cosmic essence and destiny",
    servicesDescription: [
      `Embark on a cosmic journey into the world of your beloved pets with our Pet Astrology Report. Imagine the stars aligning to unveil unique insights into your furry friend's health, happiness, and the cosmic bond you share.`,

      `Explore personalized astrological analyses that shed light on your pet's well-being, potential health concerns, and the celestial factors influencing their overall happiness. Similar to how constellations guide navigators, astrology can provide you with the compass to understand and enhance your pet's life. Consider the example of how planetary influences may indicate periods of vitality or potential health issues and in case of pet lost, Astrology could provide planetary insights about finding your pet.`,

      `Astrology empowers you to deepen your connection with your pets, ensuring their journey is guided by the cosmic energies that surround them.`,
    ],
  },

  {
    index: 19,
    serviceTitle: "Miscellaneous Queries",
    servicePrice: "$95",
    serviceImage: miscQueries,
    serviceSubtitle:
      "Ask the stars anything, from random musings to curious questions; cosmic insights await your queries.",
    servicesDescription: [
      `For any miscellaneous inquiry, our Astrology Consultation offers cosmic insights and solutions. Whether it's a question about lost items, theft, or daily basis queries. Explore astrological analyses that address your specific queries, astrology can navigate you through various aspects of life, offering clarity and direction.`,

      `Will I get back my lost item? When will my husband leave drinking? Will I get party?
      When will I have peace in my house? When will I have peace in my life? Am I cursed by black magic or witchcraft? Will I get salvation? Will my new venture be a successful? When will the husband/wife come back? What sort of person Mr. X is? When will my desire fulfilled?
      Will I appear on TV? Who will win the football match? Will my son win the football game?
      Or any ask other concern`,

      `Let the stars guide you through the diverse cosmos of questions with our Astrology Consultation, where every inquiry finds its celestial solution.`,
    ],
  },
  // {
  //   serviceTitle: "Numerology",
  //   serviceDetails: ""
  // },
  // {
  //   serviceTitle: "Numerology",
  //   serviceDetails: ""
  // },
  // {
  //   serviceTitle: "Numerology",
  //   serviceDetails: ""
  // },
  // {
  //   serviceTitle: "Numerology",
  //   serviceDetails: ""
  // },
  // {
  //   serviceTitle: "Numerology",
  //   serviceDetails: ""
  // },
  // {
  //   serviceTitle: "Numerology",
  //   serviceDetails: ""
  // },
  // {
  //   serviceTitle: "Numerology",
  //   serviceDetails: ""
  // },
  // {
  //   serviceTitle: "Numerology",
  //   serviceDetails: ""
  // },
  // {
  //   serviceTitle: "Numerology",
  //   serviceDetails: ""
  // },
  // {
  //   serviceTitle: "Numerology",
  //   serviceDetails: ""
  // },
  // {
  //   serviceTitle: "Numerology",
  //   serviceDetails: ""
  // },
];
