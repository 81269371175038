import { Modal } from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CheckboxComponent, CustomButton, CustomDropdown, DatePickerComponent, DateTimeInput, Footer, InputComponent, Loading, ModalComponent, SvgIcon, TileComponent } from '../../../components';
import PaypalComponent from '../../../components/PaypalComponent/PaypalComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetIsPaymentDone, setLoading } from "../../../store/slice";
import { getAstroData } from '../../../store/selectors';
import { serviceFormValidate } from './validation';
import { URLRoutes } from "../../../URLRoutes";
import { genderOptions, martialOptions } from './utils';
import './ServicesPayment.scss';

function ServicesPayment() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isLoading, singleQuestionData, cartItems, isPaymentDone } = useSelector(getAstroData);
  // const astroData = useSelector(getAstroData);
  const [formValue, setFormValue] = useState({});
  const ref = useRef(null);
  const { client: { services } } = URLRoutes;

  const isSingleQuestionEmpty = useMemo(() => {
    return singleQuestionData.question === '';
  }, [singleQuestionData])

  const isCartEmpty = useMemo(() => {
    return cartItems?.length !== 0;
  }, [cartItems]);

  const cartTotal = useMemo(() => {
    return cartItems?.reduce((accumulator, currentValue) => {
      const price = currentValue.serviceCardPrice.match(/\d+/g); // Extract numbers
      const numericValue = price ? Number(price[0]) : 0; // Convert to number or default to 0
      return accumulator + numericValue;
    }, 0);
  }, [cartItems]);

  useEffect(() => {
    ref.current?.scrollIntoView({ block: "start", behaviour: "smooth" });
  })

  useEffect(() => {
    if (!isCartEmpty && isSingleQuestionEmpty) {
      navigate(services);
    }
  }, [isCartEmpty, isSingleQuestionEmpty, navigate, services]);

  const payPrice = useMemo(() => {
    if (isCartEmpty) {
      return cartTotal;
    } else if (!isSingleQuestionEmpty && singleQuestionData) {
      return parseFloat(singleQuestionData.servicePrice.replace(/[^\d.]/g, ''));
    }
  }, [isCartEmpty, cartTotal, isSingleQuestionEmpty, singleQuestionData]);

  console.log("payPrice", payPrice);

  useMemo(() => {
    setFormValue((prev) => ({
      ...prev,
      singleQuestion: singleQuestionData.question,
    }))
  }, [singleQuestionData.question]);

  // console.log("single question form value", formValue);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    contactNo: '',
    gender: '',
    currentLocation: '',
    martialStatus: '',
    placeOfBirth: '',
    dateOfBirth: '',
    timeOfBirth: '',
    bookingDate: '',
    bookingTime: '',
    prashanCode: '',
    comments: '',
    paymentAgree: false
  }

  const onSubmitPaymentForm = (values, actions) => {
    setFormValue((prev) => {
      return {
        ...prev,
        ...values,
        servicePrice: payPrice,
        serviceList: cartItems?.map((item) => item.serviceName),
      }
    });
    // setFormValue(values)
    console.log("after value", formValue);
    console.log("isPaymentDone", isPaymentDone);
    // dispatch({ type: "SEND_PAYMENT_FORM", payload: formValue });
    // actions.resetForm();
  }

  const handleNavigateBack = () => {
    navigate(services);
  }

  useMemo(() => {
    if (isPaymentDone) {
      console.log("Payment is Successful", formValue)
      toast.success("Payment is Successful please check your email from @astrologicalstars");
      dispatch({ type: "SEND_PAYMENT_FORM", payload: formValue });
      dispatch(setLoading());
      dispatch(resetIsPaymentDone());
      navigate(services);
    }
  }, [navigate, isPaymentDone, dispatch, formValue, services]);


  const renderCartItems = (item, index) => {
    const { serviceName, serviceCardPrice } = item;
    return (
      <div key={`renderCartItems-index-${index}`} className='services-payment-checkout-list'>
        <span>{serviceName}</span>
        <span>{serviceCardPrice}</span>
      </div>
    )
  }

  return (
    <div className='services-payment-container' ref={ref}>
      <div className="service-payment-title-container">
        <CustomButton
          noBackground
          noPadding
          onClick={handleNavigateBack}
        >
          <SvgIcon
            logoName={{ circleLeft: true }}
            size={{ customSize: "4rem" }}
            baseClassname={"service-payment-button-icon"}
          />
        </CustomButton>
      </div>
      <TileComponent baseclassname={"services-payment-form-tile-container"}>
        <h1 className='services-payment-title'>Please fill out the required information </h1>
        <span className='services-payment-subtitle'>Note: Please make sure you filled the correct information asked in this form</span>
        <span className='services-payment-subtitle'>Note: Make sure you type the form and Submit Button will only enable when you'll fill your required fields</span>
        <Formik
          initialValues={initialValues}
          validate={serviceFormValidate}
          onSubmit={onSubmitPaymentForm}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isValid,
            setFieldValue
          }) => {
            console.log(values);
            return (
              <Form onSubmit={handleSubmit} className='services-payment-form-container'>
                {!isSingleQuestionEmpty && !isCartEmpty &&
                  <div className='services-payment-single-question-data'>
                    <div className='services-payment-single-question-name-container'>
                      <span className='question-title'>Single Question Asked:-</span>
                      <span>{singleQuestionData.question}</span>
                    </div>
                    <div className='services-payment-single-question-price-container'>
                      <span className='question-price'>Service Price</span>
                      <span>{singleQuestionData.servicePrice}</span>
                    </div>
                  </div>
                }
                {isCartEmpty &&
                  <div className="services-payment-checkout-container">
                    <h2>Cart Items</h2>
                    {cartItems?.map(renderCartItems)}
                    <div className='services-payment-checkout-total'>
                      <span>Total: </span>
                      <span>${cartTotal}</span>
                    </div>
                  </div>
                }
                {/* <div className='services-payment-name-email-container'>
                  </div> */}

                <div className='services-payment-form-details-container'>
                  <InputComponent
                    paymentStyle
                    transparentBlueTheme
                    baseclassname={"service-payment-name-input"}
                    label={"First Name"}
                    inputType={"text"}
                    onChange={handleChange}
                    name={'firstName'}
                    value={values.firstName}
                    errors={errors.firstName}
                    touched={touched.firstName}
                  />
                  <InputComponent
                    paymentStyle
                    transparentBlueTheme
                    baseclassname={"service-payment-name-input"}
                    label={"Last Name"}
                    inputType={"text"}
                    onChange={handleChange}
                    name={'lastName'}
                    value={values.lastName}
                    errors={errors.lastName}
                    touched={touched.lastName}
                  />
                  <InputComponent
                    paymentStyle
                    transparentBlueTheme
                    baseclassname={"service-payment-email-input"}
                    label={"Email"}
                    inputType={"text"}
                    onChange={handleChange}
                    name={'email'}
                    value={values.email}
                    errors={errors.email}
                    touched={touched.email}
                  />
                  <InputComponent
                    paymentStyle
                    transparentBlueTheme
                    baseclassname={"service-payment-contact-input"}
                    label={"Contact No."}
                    inputType={"text"}
                    onChange={handleChange}
                    name={'contactNo'}
                    value={values.contactNo}
                    errors={errors.contactNo}
                    touched={touched.contactNo}
                  />
                  <InputComponent
                    paymentStyle
                    transparentBlueTheme
                    baseclassname={"service-payment-contact-input"}
                    label={"Current Location"}
                    inputType={"text"}
                    onChange={handleChange}
                    name={'currentLocation'}
                    value={values.currentLocation}
                    errors={errors.currentLocation}
                    touched={touched.currentLocation}
                  />
                  <DatePickerComponent
                    paymentStyle
                    transparentBlueTheme
                    baseClassName={"service-payment-date-birth-input"}
                    label={"Date of Birth"}
                    inputType={"date"}
                    maxDate={new Date()}
                    minDate={{}}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    name={'dateOfBirth'}
                    value={values.dateOfBirth}
                    errors={errors.dateOfBirth}
                    touched={touched.dateOfBirth}
                  />
                  <InputComponent
                    paymentStyle
                    transparentBlueTheme
                    baseclassname={"service-payment-time-birth-input"}
                    label={"Time of Birth"}
                    inputType={"time"}
                    onChange={handleChange}
                    name={'timeOfBirth'}
                    value={values.timeOfBirth}
                    errors={errors.timeOfBirth}
                    touched={touched.timeOfBirth}
                  />
                  <InputComponent
                    paymentStyle
                    transparentBlueTheme
                    baseclassname={"service-payment-place-birth-input"}
                    label={"Place of Birth (Town, City, Province, Country)"}
                    inputType={"text"}
                    onChange={handleChange}
                    name={'placeOfBirth'}
                    value={values.placeOfBirth}
                    errors={errors.placeOfBirth}
                    touched={touched.placeOfBirth}
                  />
                  <CustomDropdown
                    // paymentStyle
                    transparentBlueTheme
                    baseClassName={"service-payment-contact-input"}
                    label={"Martial Status"}
                    inputType={"text"}
                    handleChange={handleChange}
                    name={'martialStatus'}
                    options={martialOptions}
                    value={values.martialStatus}
                    errors={errors.martialStatus}
                    touched={touched.martialStatus}
                  />
                  {/* <InputComponent
                    paymentStyle
                    transparentBlueTheme
                    baseclassname={"service-payment-contact-input"}
                    label={"Gender"}
                    inputType={"text"}
                    onChange={handleChange}
                    name={'gender'}
                    value={values.gender}
                    errors={errors.gender}
                    touched={touched.gender}
                  /> */}
                  <CustomDropdown
                    // paymentStyle
                    transparentBlueTheme
                    baseClassName={"service-payment-contact-input"}
                    label={"Gender"}
                    inputType={"text"}
                    handleChange={handleChange}
                    name={'gender'}
                    options={genderOptions}
                    value={values.gender}
                    errors={errors.gender}
                    touched={touched.gender}
                  />
                  <InputComponent
                    contactInputStyle
                    transparentBlueTheme
                    baseclassname={"service-payment-comment-input"}
                    label={"Prashna Code 1 to 249 (if applicable)"}
                    inputType={"text"}
                    onChange={handleChange}
                    name={'prashanCode'}
                    value={values.prashanCode}
                  />
                  {/**TODO Desired booking Date & Time */}
                  {/* <DateTimeInput
                    transparentBlueTheme
                    baseClassname={'service-payment-booking-date-time-input'}
                    label={"Desired Booking Date & Time"}
                    dateName={'bookingDate'}
                    timeName={'bookingTime'}
                    dateValue={values.bookingDate}
                    timeValue={values.bookingTime}
                    handleChange={handleChange}
                    dateErrors={errors.bookingDate}
                    timeErrors={errors.bookingTime}
                    dateTouched={touched.bookingDate}
                    timeTouched={touched.bookingTime}
                  /> */}
                  <DatePickerComponent
                    transparentBlueTheme
                    baseClassName={'service-payment-booking-date-time-input'}
                    label={"Desired Booking Date"}
                    name={'bookingDate'}
                    minDate={new Date()}
                    maxDate={{}}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    value={values.bookingDate}
                    errors={errors.bookingDate}
                    touched={touched.bookingDate}
                  />
                  <InputComponent
                    paymentStyle
                    transparentBlueTheme
                    baseclassname={"service-payment-time-birth-input"}
                    label={"Desired Booking Time"}
                    inputType={"time"}
                    onChange={handleChange}
                    name={'bookingTime'}
                    value={values.bookingTime}
                    errors={errors.bookingTime}
                    touched={touched.bookingTime}
                  />
                  <InputComponent
                    contactInputStyle
                    transparentBlueTheme
                    baseclassname={"service-payment-comment-input"}
                    label={"Brief Comments (if applicable)"}
                    inputType={"areabox"}
                    onChange={handleChange}
                    name={'comments'}
                    value={values.comments}
                  // errors={errors.comments}
                  // touched={touched.comments}
                  />
                  <CheckboxComponent
                    name={'paymentAgree'}
                    handleChange={handleChange}
                    value={values.paymentAgree}
                    errors={errors.paymentAgree}
                    touched={touched.paymentAgree}
                    label={"By submitting this form, I acknowledge that accurate birth details are crucial for precise insights. I understand that the team will use the provided information solely for astrological analysis and guidance."}
                  />
                </div>
                {/* {errors.password && touched.password && errors.password} */}
                {/* {isValid && */}
                <div className="services-payment-form-button-container">
                  {/* <CustomButton
                      round
                      blueTheme
                      // disabled={isSubmitting}
                      type={"submit"}
                      textclassname={"services-payment-submit-buttontext"}
                      buttonText={"Submit Now"}
                    /> */}
                  <ModalComponent
                    header={"Pay and Place your order"}
                    trigger={
                      <CustomButton
                        round
                        blueTheme
                        // disabled={isSubmitting}
                        type={"submit"}
                        textclassname={"services-payment-submit-buttontext"}
                        buttonText={"Submit"}
                      />
                    }
                    content={
                      <>
                        {isValid ?
                          <>
                            <h1>Cart Total ${payPrice}</h1>
                            <PaypalComponent totalAmount={payPrice} description={"Book your service @astrologicalstars"} />
                            <p>Before doing payment check your form details before submitting it, wrong information may results in accuracy of prediction</p>
                            <ul>
                              <li>Before proceeding, please reach us via Direct Phone Call for Appointment Availability of your desired date and time.</li>
                              <li>All consulting services will be provided only after confirmation of payments</li>
                              <li>Refund Policy: Our refund policy is designed to be fair and transparent. If you have any concerns please review our policy.</li>
                            </ul>
                            <p>Your payment confirms your trust in our services. We appreciate your business and look forward to providing you with the exceptional service you deserve.</p>
                          </> : 
                          <h1>You missed a field in the form, please check and submit again</h1>
                        }
                      </>
                    }
                  />
                </div>
                {/* } */}
                {/* {isLoading && <Loading />} */}
              </Form>
            )
          }}
        </Formik>
      </TileComponent>
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default ServicesPayment;