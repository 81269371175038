import { homeHeaderRoutes } from './utils';
import { Icon } from 'semantic-ui-react';
import CustomButton from '../CustomButton/CustomButton';
import Menubar from '../Menubar/Menubar';
import SvgIcon from "../SvgIcon/SvgIcon";
import './Header.scss';
import { NavLink } from 'react-router-dom';

function Header({ handleToggleMenu }) {

  return (
    <div className='header-container'>
      <div className="header-area">
        {/* <span className='header-title'>Astrologer</span> */}
        <NavLink to={"/"}>
          <SvgIcon logoName={{ applogo: true }} size={{ customSize: "10rem" }} baseClassname={"header-app-logo"}/>
        </NavLink>
        <Menubar position={"header"} headersLinks={homeHeaderRoutes} />
        <CustomButton
          halfPadding
          noBackground
          onClick={handleToggleMenu}
          baseclassname={"menu-button"}
        >
          {/* <Icon name={`${themeColor ? 'moon' : 'sun'}`} className='theme-icon'/> */}
          <Icon name={`indent`} className='menu-icon' />
        </CustomButton>
      </div>
    </div>
  )
}

export default Header;