import { URLRoutes } from "./URLRoutes";
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import {
  About,
  // Appointments,
  Contact,
  Home,
  HomeContainer,
  Learn,
  // Payment,
  Services,
  ServiceDescription,
  ServicesPayment,
  // Services,
} from "./containers";
// import ServiceConstruction from "./containers/Services/ServiceConstruction";
// import PaypalComponent from "./components/PaypalComponent/PaypalComponent";

function App() {
  const [tileDescription, setTileDescription] = useState({});
  const {
    home,
    services,
    // appointment,
    learn,
    about,
    contact,
    // payment,
    servicesDescription,
    servicesPayment,
  } = URLRoutes.client;

  return (
    <Routes>
      <Route path="/" element={<HomeContainer />}>
        <Route index element={<Home />} />
        <Route path={home} element={<Home />} />
        {/* <Route path={services} element={<ServiceConstruction />} /> */}
        <Route path={services} element={<Services setTileDescription={setTileDescription}/>} />
        <Route path={servicesDescription} element={<ServiceDescription {...tileDescription}  />} />
        <Route path={servicesPayment} element={<ServicesPayment />} />
        {/* <Route path={appointment} element={<Appointments />} /> */}
        <Route path={about} element={<About />} />
        <Route path={learn} element={<Learn />} />
        <Route path={contact} element={<Contact />} />
        {/* <Route path={payment} element={<Payment />} /> */}
        <Route path="*" element={<Home />}/>
      </Route>
    </Routes>
  );
}

export default App;
