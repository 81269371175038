import { Header, Menubar, ScrollTop } from "../../components";
import { Outlet, useLocation } from "react-router-dom";
import { homeHeaderRoutes } from "../../components/Header/utils";
// import bgVideo from "../../assets/homepage-video.mp4";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { URLRoutes } from "../../URLRoutes";
import { Analytics } from "@vercel/analytics/react";
import "./HomeContainer.scss";

const locations = [
  URLRoutes.client.home,
  URLRoutes.client.about,
  URLRoutes.client.contact,
]

function HomeContainer() {
  const [showSidebar, setShowsidebar] = useState(false);
  const handleCloseMenu = () => {
    setShowsidebar(false);
  }

  const handleToggleMenu = () => {
    setShowsidebar((prev) => !prev)
  }

  return (
    <div className="home-container">
      <Header handleToggleMenu={handleToggleMenu} />
      <div className="outlet-container" onClick={handleCloseMenu}>
        <Outlet />
        <Menubar headersLinks={homeHeaderRoutes} position={"sidebar"} showSideBar={showSidebar} handleToggleMenu={handleToggleMenu} />
      </div>
      <Analytics />
    </div>
  )
}

export default HomeContainer;