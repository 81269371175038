export const client = {
  home: "/home",
  services: "/services",
  servicesDescription: '/services-description',
  appointment: "/appointment",
  learn: "/learn",
  about: "/about",
  contact: "/contact",
  payment: "/payment",
  servicesPayment: "/services-payment",
}