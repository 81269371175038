// import { Icon } from 'semantic-ui-react';
import { contactFaq } from './utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton, Footer, InputComponent, Loading, SvgIcon, TileComponent } from '../../components';
import { getAstroData } from '../../store/selectors';
import { setLoading } from '../../store/slice';
// import { Loader } from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import { contactValidation } from './validation';
import './Contact.scss';

function Contact() {
  const dispatch = useDispatch();
  const selector = useSelector(getAstroData);
  const { isLoading } = selector;
  const [tileOpen, setTileOpen] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ block: "start", behaviour: "smooth" });
  });

  const initialValues = {
    name: '',
    email: '',
    message: ''
  }

  const onSubmitMessage = (values, actions) => {
    dispatch({ type: "SEND_EMAIL", payload: values });
    dispatch(setLoading());
    actions.resetForm();
    console.log(values);
  }

  console.log("selector", selector);

  useMemo(() => {
    // toast.success(selector.message);
    if (selector.message === 'Data Sent Successfully' && selector.isLoading === false) {
      console.log('email true');
      toast.success("Email is sent to @astrologicalstars");
    } else {
      // toast.error(selector.message);
      console.log('not truee');
    }
  }, [selector.message, selector.isLoading])

  const handleToggleTile = (index) => {
    if (tileOpen === index) {
      setTileOpen(null);
    } else {
      setTileOpen(index);
    }
  }
  const renderFaqTiles = (item, index) => {
    const { faqTitle, faqAnswer } = item;
    const isOpen = tileOpen === index;

    const handleTileOpen = () => {
      handleToggleTile(index);
    }

    return (
      <TileComponent baseclassname={"faq-tiles-main-container"} handleClick={handleTileOpen}>
        <div className='faq-title-area'>
          <div className="faq-title-question">
            <SvgIcon customIcon logoName={{ starLogo: true }} size={{ xLarge: true }} baseClassname={"faq-title-question-icon"} />
            <span className='faq-title-span-text'>{faqTitle}</span>
          </div>
          <SvgIcon customIcon logoName={{ plusRoundIcon: true }} size={{ customSize: "3rem" }} baseClassname={"faq-title-question-icon"} />
        </div>
        {isOpen &&
          <div className='faq-tile-answer-text'>{faqAnswer}</div>}
      </TileComponent>
    )
  }
  return (
    <div className='contact-container' ref={ref}>
      <div className="contact-main-image-container">
        <h1 className='contact-us-introduction-title'>Contact us</h1>
        <span className='contact-us-intro-text'>At Astrological stars, your satisfaction and success are our top priorities. We are delighted to provide you with a variety of ways to get in touch with us, whether you have questions, feedback, or require assistance. Your inquiries are important to us, and we're here to make your experience exceptional.</span>

        <div className="contact-connect-us-areabox">

          <TileComponent blueShade1 baseclassname={"contact-get-in-touch-container"}>
            <h2 className='contact-get-in-touch-title'>Get in touch with us</h2>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmitMessage}
              validate={contactValidation}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form >
                  <InputComponent
                    contactInputStyle
                    label={"Name"}
                    inputType={"text"}
                    onChange={handleChange}
                    name={'name'}
                    value={values.name}
                  />
                  {errors.name && touched.name && <span className='formik-error-message'>{errors.name}</span>}
                  <InputComponent
                    contactInputStyle
                    label={"Email"}
                    inputType={"text"}
                    onChange={handleChange}
                    name={'email'}
                    value={values.email}
                  />
                  {errors.email && touched.email && <span className='formik-error-message'>{errors.email}</span>}
                  <InputComponent
                    contactInputStyle
                    label={"Message"}
                    inputType={"areabox"}
                    onChange={handleChange}
                    name={'message'}
                    value={values.message}
                  />
                  {errors.message && touched.message && <span className='formik-error-message'>{errors.message}</span>}
                  {/* {errors.password && touched.password && errors.password} */}
                  <div className="contact-form-button-container">
                    <CustomButton
                      round
                      blueTheme
                      // disabled={isSubmitting}
                      type={"submit"}
                      textclassname={"contact-form-submit-buttontext"}
                      buttonText={"Submit"} />
                    {isLoading && <Loading />}
                  </div>
                </Form>
              )}
            </Formik>
          </TileComponent>

          <TileComponent blueShade1 baseclassname={"contacts-astrologer-information-container"}>
            <div className='contact-astrologer-card-row'>
              <TileComponent blueShade1 baseclassname={"contact-astrologer-info-card"}>
                <SvgIcon logoName={{ locationLogo: true }} size={{ customSize: "3rem" }} baseClassname={"contact-loation-logo"} />
                <span className='contact-astrologer-info-span-text'>Toronto, CA</span>
              </TileComponent>
              <TileComponent blueShade1 baseclassname={"contact-astrologer-info-card"}>
                {/* <SvgIcon logoName={{ locationLogo: true }} size={{ customSize: "3rem" }} baseClassname={"contact-loation-logo"} /> */}
                <Icon name='mail outline' className='contact-tile-email' />
                <a href="mailto:astrologicalstars249@gmail.com" className='contact-astrologer-info-email'>Mail to us</a>
              </TileComponent>
            </div>
            <div className='contact-astrologer-card-row contact-astrologer-card-row-1'>
              <TileComponent blueShade1 baseclassname={"contact-astrologer-info-card"}>
                <SvgIcon logoName={{ callLogo: true }} size={{ customSize: "3rem" }} baseClassname={"contact-loation-logo"} />
                <a className='contact-astrologer-info-span-text' href='tel:+16472291843'>+1-647-229-1843</a>
              </TileComponent>
              <TileComponent blueShade1 baseclassname={"contact-astrologer-info-card"}>
                <Icon name='whatsapp' className='contact-tile-whatsapp' />
                <a className='contact-astrologer-info-whatsapp' href="https://wa.me/message/GTQ4WUYJTIUSN1">Text me WhatsApp</a>
              </TileComponent>
            </div>
          </TileComponent>

        </div>
        <div className="contact-us-faq-tiles-container">
          {contactFaq.map(renderFaqTiles)}
        </div>
        <TileComponent baseclassname={"contact-us-last-tile-container"}>
          <span className="contact-us-last-tile-text-1">
            At Astrological stars, your trust and support mean the world to us. We want to extend our heartfelt gratitude for choosing us as your preferred service provider. Your satisfaction and success drive our commitment to excellence. Thank you for being a valued part of our journey.
          </span>
          <span className="contact-us-last-tile-text-2">
            Whether it's for your next project, inquiry, or simply to say hello, we look forward to your return. Visit us again soon, and let's continue our journey together.
          </span>
        </TileComponent>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default Contact