export const serviceFormValidate = (values) => {
  const errors = {};

  /**@desc Name Validation */
  if (!values.firstName) {
    errors.firstName = "Required First Name";
  }

  /**@desc Name Validation */
  if (!values.lastName) {
    errors.lastName = "Required Last Name";
  }

  /**@desc Email Validation */
  if (!values.email) {
    errors.email = "Required Email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    console.log("Not a valid email");
    errors.email = "Not a valid email";
  }

  /**@desc Age Validation */
  if (!values.gender) {
    errors.gender = "Please Enter your Gender";
  } else if (values.gender === "select_any_option") {
    errors.gender = "Please select a valid option";
  }

  /**@desc Comment Validation */
  if (!values.contactNo) {
    errors.contactNo = "Required Contact No";
  }

  /**@desc Comment Validation */
  if (!values.currentLocation) {
    errors.currentLocation = "Required Current Location";
  }

  /**@desc Comment Validation */
  if (!values.placeOfBirth) {
    errors.placeOfBirth = "Required Place of Birth";
  }

  /**@desc Comment Validation */
  if (!values.dateOfBirth) {
    errors.dateOfBirth = "Required Date of Birth";
  }

  /**@desc Comment Validation */
  if (!values.timeOfBirth) {
    errors.timeOfBirth = "Required Time of Birth";
  }

  /**@desc Booking Date Validation */
  if (!values.martialStatus) {
    console.log("values.martialStatus", values.martialStatus);
    errors.martialStatus = "Please select an option";
  } else if (values.martialStatus === "select_any_option") {
    errors.martialStatus = "Please select a valid option";
  }

  /**@desc Booking Date Validation */
  if (!values.bookingDate) {
    errors.bookingDate = "Required Desired Booking Date";
  }

  /**@desc Comment Validation */
  if (!values.bookingTime) {
    errors.bookingTime = "Required Desired Booking Time";
  }

  // /**@desc Comment Validation */
  // if (!values.comments) {
  //   errors.comments = "Required Comment";
  // }

  /**@desc paymentAgree Validation */
  if (!values.paymentAgree) {
    errors.paymentAgree = "Check this box to Agree";
  }

  return errors;
};
