import {Footer} from '../../components';

function Payment() {
  return (
    <div>
      <Footer />
    </div>
  )
}

export default Payment