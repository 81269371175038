export const contactValidation = (values) => {
  const errors = {};

  /**@desc Name Validation */
  if (!values.name) {
    errors.name = "Required Name";
  }
  
  /**@desc Email Validation */
  if (!values.email) {
    errors.email = "Required Email";
  }

  /**@desc Email Validation */
  if (!values.message) {
    errors.message = "Required Message";
  }

  return errors;
}