import { NavLink } from 'react-router-dom';
import { Footer } from '../../components';
import "./Learn.scss"

function Learn() {
  return (
    <div className='learn-container'>
      <div className="learn-box">
      <h1>Learn page is under construction</h1>
      <NavLink to="/"><h1>Go back to Home Page</h1></NavLink>
      </div>
      <Footer />
    </div>
  )
}

export default Learn