import ReactDatePicker from 'react-datepicker';
import classNames from 'classnames';
import { useState } from 'react';
import './DatePickerComponent.scss';

function DatePickerComponent({
  transparentBlueTheme,
  baseClassName,
  label,
  name,
  minDate,
  maxDate,
  handleChange,
  setFieldValue,
  value,
  errors,
  touched
}) {
  // const [startDate, setStartDate] = useState(new Date());

  
  return (
    <div className={classNames(["custom-react-date-picker-container", baseClassName], {
      'input-transparent-blue-theme': transparentBlueTheme
    })}>
      <div className="custom-datepicker-box">
        <span className='custom-react-date-picker-label'>{label}</span>
        <ReactDatePicker
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date) => {
            // handleChange(name, date.toISOString())
            setFieldValue(name, date.toISOString())
          }}
          selected={value}
          // value={value}
          name={name}
          className={"custom-react-date-picker-component"}
        />
      </div>
      {errors && touched && <span className='formik-input-errors'>{errors}</span>}
    </div>
  )
}

export default DatePickerComponent