import { Footer, TileComponent } from '../../components';
import { useEffect, useRef } from 'react';
import { aboutImg, image1, image2, image4, image5 } from './assets';
import "./About.scss";

function About() {
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ block: "start", behaviour: "smooth" });
  });
  
  return (
    <div ref={ref} className='about-container'>
      <TileComponent blueShade baseclassname={"about-introduction-tile-container"}>
        <div className="about-introduction-container">
          <img src={aboutImg} alt="about astrologer img" className='about-astrologer-image' />
          <div className="about-intro-main-text">
            <h1 className='about-who-am-i-title'>Amanpreet Singh</h1>
            <span className='about-astrologer-span-text'>Nadi Astrologer | Sepharial Numerologist | Contemporary Palm Reader</span>
            <span className='about-astrologer-span-text'>M.D Supply Chain Management | M.A Classical Music | B.Com </span>
            <span className='about-astrologer-span-text'>FSRA Approved Mortgage Agent Level 2  M22001360</span>
          </div>
        </div>
      </TileComponent>

      <TileComponent blueShade baseclassname={"about-introduction-tile-container"}>
        <div className="about-astro-first-container">
          <div className="about-intro-first-details about-intro-span-first">
            <span>Mr.Singh stands as an accomplished prediction practitioner with a passion for providing accurate predictions on future events. He has honed his skills in these fields through extensive study and practice, and is now ready to embark on a career in this exciting and rewarding field. His expertise in Astrology, Numerology and Palmistry allows him to provide valuable insights into the lives of his clients. He has a deep understanding of the principles and practices of these fields and is able to use this knowledge to help people make informed decisions about their lives.</span>
          </div>
          <img src={image1} alt="" className="about-first-container-image-1" />
        </div>
      </TileComponent>

      <TileComponent blueShade baseclassname={"about-introduction-tile-container"}>
        <div className="about-astro-first-container">
          <img src={image2} alt="" className="about-first-container-image-2" />
          <div className="about-intro-first-details about-intro-span-second">
            <span>Graced by the guidance of the great teacher <span style={{ fontWeight: "bolder" }}>Mr. Umang Taneja, often revered as the “Father of Nadi Astrology”</span>, Singh feels profoundly fortunate to be his disciple. </span>
            <span style={{ paddingTop: "2rem" }}>His wisdom and teachings have not only shaped him into a skilled astrologist but have also instilled a deep sense of gratitude and respect.</span>
            <span style={{ paddingTop: "2rem" }}>He humbly acknowledges the invaluable mentorship of Mr. Taneja, whose influence continues to illuminate his journey in the realm of astrology.</span>
          </div>
        </div>
      </TileComponent>

      <TileComponent blueShade baseclassname={"about-introduction-tile-container"}>
        <div className="about-astro-first-container">
          <div className="about-intro-first-details about-intro-span-third">
            <span>With his keen eye for detail and his ability to interpret complex data, Singh is well-equipped to provide accurate and insightful predictions on a wide range of topics. Whether it's love, career, or finances, he has the skills and knowledge to help his clients navigate the challenges of life with confidence and clarity. In addition to his expertise in Astrology and Numerology, he is also a skilled communicator and a natural problem-solver. He is able to work closely with his clients to understand their needs and concerns and is always willing to go the extra mile to ensure their satisfaction.
            </span>
          </div>
          <img src={image4} alt="" className="about-first-container-image-3" />
        </div>
      </TileComponent>

      <TileComponent blueShade baseclassname={"about-introduction-tile-container"}>
        <div className="about-astro-first-container">
          <img src={image5} alt="" className="about-first-container-image-4" />
          <div className="about-intro-first-details about-intro-span-fourth">
            <span className='about-astrologer-span-text'>He invites you to embark on a journey of self-discovery, tapping into the rhythmic vibrations of the universe and unlocking the secrets of your own destiny.
            </span>
            <span className='about-astrologer-span-text-last'>Step into a cosmic world where science and magic intertwine, where numbers and stars tell stories, and where Amanpreet is your guiding light on the path to self-awareness and enlightenment.</span>
          </div>
        </div>
      </TileComponent>

      <Footer />
    </div>
  )
}

export default About;