import { NavLink, useNavigate } from 'react-router-dom';
import CustomButton from '../CustomButton/CustomButton';
import ModalComponent from "../ModalComponent/ModalComponent";
import siteConstruction from "./siteConstruction.jpeg";
import { URLRoutes } from '../../URLRoutes';
import './SkillCard.scss';

function SkillCard({
  cardName,
  cardSubtitle,
  cardImage,
  cardDesc,
  cardLink,
  index
}) {

  const navigate = useNavigate();
  const { client: { services } } = URLRoutes;
  
  const handleNavigateService = () => {
    navigate(services);
  }

  return (
    <div className="flip-card" key={`renderCardindex-${index}`}>
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <div className='flip-card-front-title'>
            <h2>{cardName}</h2>
            {cardSubtitle && <h3>{cardSubtitle}</h3>}
          </div>
          <img src={cardImage} alt="Avatar" className='flip-card-front-image' />
        </div>
        <div className="flip-card-back">
          <span className='flip-card-back-text'>{cardDesc}</span>
          {/* <ModalComponent
          blueTheme
            header={"Site under Construction"}
            trigger={
              <CustomButton round blueTheme baseclassname={"flipcard-button"}>
                <span className="flipcard-link">Read More</span>
              </CustomButton>
            }
            content={
              <>
                <img src={siteConstruction} alt='siteConstruction' style={{width: "100%"}} />
              </>
            }
          /> */}
          <CustomButton round blueTheme baseclassname={"flipcard-button"} onClick={handleNavigateService}>
            <span className="flipcard-link">Read More</span>
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

export default SkillCard;