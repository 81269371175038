import { Dropdown } from "semantic-ui-react";
import "./CustomDropdown.scss";
import classNames from "classnames";

function CustomDropdown({
  label,
  baseClassName,
  transparentBlueTheme,
  name,
  value,
  errors,
  touched,
  fluid,
  options,
  handleChange
}) {

  const renderDropdownOptions = (item, index) => {
    return(
      <option key={`renderDropdownOption-index-${index}`} value={item.value} className="custom-dropdown-option">{item.text}</option>
    )
  }

  return (
    <div className={classNames(["custom-dropdown-container", baseClassName],{
      'input-transparent-blue-theme': transparentBlueTheme
    })}>
      <div className="custom-dropdown-box">
        <span className="custom-dropdown-label">{label}</span>
        {/* <Dropdown
          name={name} 
          
          fluid={fluid}
          value={value}
          options={options}
          onChange={handleChange}
          className="custom-dropdown-component"
        /> */}
        <select name={name} className="custom-dropdown-component" onChange={handleChange} value={value}>
          {options?.map(renderDropdownOptions)}
        </select>
      </div>
      {errors && touched && <span className="formik-input-errors">{errors}</span>}
    </div>
  )
}

export default CustomDropdown;