import { useEffect, useState } from 'react'
import { Icon, Modal } from 'semantic-ui-react';
import CustomButton from "../CustomButton/CustomButton";
import classNames from "classnames";
import "./ModalComponent.scss";

function ModalComponent({ trigger, header, content, blueTheme, noPaddingCloseButton, modalState }) {
  const [open, setOpen] = useState(modalState);
  const { Header, Content } = Modal;

  const handleModalClose = () => {
    setOpen(false);
  }

  useEffect(()=> {
    setOpen(modalState);
  },[modalState]);
  
  // console.log("open", open);
  
  return (
    <Modal
      onClose={handleModalClose}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      className={classNames(['modalcontainer'],{
        "modal-blue-theme-container":blueTheme
      })}
    >
      <Header className="modalcontainer-header">
        <span>{header}</span>
        <CustomButton noBackground noPadding onClick={handleModalClose} baseclassname={"modal-close-button"}>
          <Icon name="close" className="modal-close-icon"/>
        </CustomButton>
      </Header>
      <Content className='modal-content-container'>
        <div className="modal-contentbox">{content}</div>
      </Content>
    </Modal>
  )
}

export default ModalComponent;