import { CustomButton, Footer, SkillCard, SvgIcon, TileComponent } from "../../components";
import cardStar from "../../assets/cardstar.png";
import tabAstro from "../../assets/tab_astro.png";
import amanpreetImg from "./amanpreet.png";
// import { NavLink } from "react-router-dom";
// import { Icon } from "semantic-ui-react";
import { skillsData } from "./utils";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { URLRoutes } from "../../URLRoutes";
import "./Home.scss"

function Home() {
  const navigate = useNavigate();
  const ref = useRef();
  const { client: { about } } = URLRoutes;

  useEffect(() => {
    ref.current?.scrollIntoView({ block: "start", behaviour: "smooth" });
  });

  const renderSkillsCards = (item, index) => {
    return (
      <SkillCard index={index} {...item} />
    )
  }

  const handleNavigatetoAbout = () => {
    navigate(about);
  }


  return (
    <div className="homepage-container" ref={ref}>
      <div className="homepage-introduction-container">

        <div className="homepage-logo-container">
          <SvgIcon logoName={{ zodiacLogo: true }} size={{ customSize: "30rem" }} spinLogo baseClassname={"astro-intro-logo"} />
          <SvgIcon logoName={{ applogo: true }} size={{ customSize: "13rem" }} baseClassname={"astro-intro-applogo"} />
        </div>

        <div className="homepage-title-container">
          <h1 className="homepage-heading-title">Welcome to the captivating</h1>
          <h1 className="homepage-heading-title">world of</h1>
          <h1 className="homepage-heading-title">Indian Predictive Astrology</h1>
        </div>
        <div className="homepage-subtitle-container">
          <span>A profound science that has guided humanity for centuries.</span>
          <span>Unlike western astrology, Indian Predictive Astrology is deeply rooted and regarded as a legitimate scientific discipline.</span>
          <span>Since ancient times, saints and scholars have denoted it as “The Science of Stars and Destiny”</span>
        </div>
      </div>
      <div className="homepage-intro-second-container">
        <TileComponent baseclassname={"homepage-intro-tile"}>
          <span className="homepage-intro-about-text">In this era of ancient wisdom and a practical approach, we explore the cosmic symphony that shapes your life's path. Astrology, a timeless art that connects you to the celestial dance of planets and stars; Numerology, the mathematical magic that deciphers the hidden codes in your name and birthdate; and Palmistry, where the lines on your hands tell stories of your past, present, and future, come together in perfect harmony. Your birth chart, numerological profile, and palm lines are more than just data – they are your cosmic blueprints, your personal guide to understanding your innate talents, challenges, and life's purpose. It's like holding the keys to your own destiny.</span>
          <span className="homepage-intro-about-text">
            We believe that true self-discovery is a multifaceted journey. By combining the wisdom of Indian Astrology, the precision of Numerology, and the art of Palmistry, we offer a holistic approach that delves deep into your personality, relationships, career, and life path. It's a complete transformational experience.
          </span>
        </TileComponent>
        <img src={cardStar} alt="" className="homepage-about-cardstar" />
      </div>
      <div className="homepage-intro-third-container">
        <TileComponent blueShade1 baseclassname={"astro-dedicate-tile"}>
          <h2>What’s ASTROLOGICAL STARS going to dedicate ?</h2>
          <span>At ASTROLOGICAL STARS we are dedicated to unlocking the mysteries of the cosmos for you. We are here to decode the intricate layers of your being. We provide you personalized readings that empower you to make informed decisions, navigate life's twists and turns, and harness the energies that surround you.</span>
        </TileComponent>
      </div>
      <div className="homepage-intro-fourth-container">
        <TileComponent baseclassname={"reveal-future-tile"}>
          <h2>Join us and reveal your future with Astrological Stars!</h2>
          <span>Are you ready to explore the incredible science of Indian Predictive Astrology and unlock the secrets of your destiny? Join us on this cosmic adventure!</span>
          <span style={{ paddingTop: "1rem" }}>Discover the fusion of ancient wisdom and modern insight here – Where the Stars, Numbers, and Lines of Your Life Intersect.</span>
        </TileComponent>
        <img src={tabAstro} alt="" className="homepage-tab-astro" />
      </div>
      <div className='skills-container'>
        <div className='skills-cardcontainer'>
          <div className="skills-cardcontainer-title">
            <h2>Unlock the cosmic secrets to a brighter future with astrological stars</h2>
            <h2>where destiny meets guidance.</h2>
          </div>
          <div className="skills-cards-area">
            {skillsData.map(renderSkillsCards)}
          </div>
        </div>
      </div>
      <div className="about-astrology-tile-container">
        <h1 className="about-astrology-home-title">About the Astrologist</h1>
        <TileComponent baseclassname={"about-astro-brief-tile-container"}>
          <img src={amanpreetImg} alt="" className="about-astrologist-image" />
          <div className="astro-brief-areabox">
            <h2 className="about-astro-brief-heading-name">Amanpreet Singh</h2>
            <h3>Nadi Astrologer | Sepharial Numerologist | Contemporary Palm Reader</h3>
            <span className="astro-brief-span-text">“Prediction is not merely an enigmatic art,  it’s a meticulously honed science“  - Amanpreet Singh

            </span>
            <div className="homepage-about-readmore-button-container">
              <CustomButton
                blueTheme
                round
                baseclassname={"homepage-about-readmore-button"}
                textclassname={"homepage-about-readmore-button-text"}
                buttonText={"Read More"}
                buttonTitle={"Go to About Page"}
                onClick={handleNavigatetoAbout}
              >
              </CustomButton>
            </div>
          </div>
        </TileComponent>
      </div>
      <Footer />
    </div>
  )
}

export default Home;
