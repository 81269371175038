import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";

// import dataHoliday from "../../src/Data.json";

// const getData = async () => {
//   let response = await fetch("https://jsonplaceholder.typicode.com/users");
//   return await response.json();
// };

const sendEmail = async (data) => {
  console.log("data", data);
  let response = await fetch("https://astrology-app-backend.vercel.app/send-email", {
  // let response = await fetch("http://localhost:4000/send-email", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
  return await response.json();
};

const sendPaymentForm = async (data) => {
  console.log("order", data);
  let response = await fetch("https://astrology-app-backend.vercel.app/place-order-email", {
  // let response = await fetch("http://localhost:4000/place-order-email", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  });
  return await response.json();
};

// function* fetchData(){
//   try {
//     let data = yield call(getData);
//     yield put({ type: "user/setData", payload: data });
//   } catch (error) {
//     yield put({ type: "user/setError" });
//   }
// }

function* sendEmailSaga({ payload }) {
  try {
    let data = yield call(() => sendEmail(payload));
    console.log("sent data", data);
    yield put({ type: "astrologicalSlice/sendEmail", payload: data });
  } catch (error) {
    yield put({ type: "astrologicalSlice/setError" });
  }
}

function* sendPaymentFormSaga({ payload }) {
  try {
    let data = yield call(() => sendPaymentForm(payload));
    console.log("sent data", data);
    toast.success("Your order has been Booked @astrologicalstars.com")
    yield put({ type: "astrologicalSlice/sendServicePaymentForm", payload: data });
  } catch (error) {
    yield put({ type: "astrologicalSlice/setError" });
  }
}

function* addToCartSaga({payload}) {
  try {
    yield put({ type: "astrologicalSlice/addToCart", payload });
  } catch (error) {
    yield put({ type: "astrologicalSlice/setError" });
  }
}

function* removeFromCartSaga({payload}) {
  try {
    yield put({ type: "astrologicalSlice/removeFromCart", payload });
  } catch (error) {
    yield put({ type: "astrologicalSlice/setError" });
  }
}

function* singleQuestionSaga({payload}) {
  console.log("single question saga", payload);
  try {
    yield put({ type: "astrologicalSlice/singleQuestionInfo", payload });
  } catch (error) {
    yield put({ type: "astrologicalSlice/setError" });
  }
}

function* rootSaga() {
  // yield takeEvery("FETCH", fetchData);
  yield takeEvery("SEND_EMAIL", (action) => sendEmailSaga(action));
  yield takeEvery("SEND_PAYMENT_FORM", (action) => sendPaymentFormSaga(action));
  yield takeEvery("ADD_TO_CART", (action) => addToCartSaga(action));
  yield takeEvery("REMOVE_FROM_CART", (action) => removeFromCartSaga(action));
  yield takeEvery("SINGLE_QUESTION_BUY", (action) => singleQuestionSaga(action));
}

export default rootSaga;
