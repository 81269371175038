import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { setIsPaymentDone } from "../../store/slice"
import { useNavigate } from "react-router-dom";

function PaypalComponent({
  totalAmount,
  description
}) {

  const [paid, setPaid] = useState(false);
  const [error, setError] = useState(null);
  const paypalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(window.paypal);

  useEffect(() => {
    window.paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          intent: "CAPTURE",
          purchase_units: [
            {
              description,
              amount: {
                currency_code: "CAD",
                value: totalAmount,
              },
            },
          ],
        });
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        setPaid(true);
        console.log(order);
      },
      onError: (err) => {
        setError(err);
        console.error(err);
      },
    })
      .render(paypalRef.current);
  }, [totalAmount, description]);

  // If the payment has been made
  if (paid) {
    dispatch(setIsPaymentDone());
    toast.success("Payment got successful");
  }
  // If any error occurs
  if (error) {
    toast.error("Error Occurred in processing payment.! Please try again.");
  }

  if (window.paypal === undefined) {
    return (
      <span>No Internet Connection</span>
    )
  }

  // Default Render
  return (
    <>
      <div ref={paypalRef} />
    </>
  );
}

export default PaypalComponent;