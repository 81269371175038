import classNames from "classnames";
import IconRef from "./IconRef";
import "./SvgIcon.scss";


function SvgIcon({
  logoName: {
    applogo,
    zodiacLogo,
    plusRoundIcon,
    locationLogo,
    callLogo,
    starLogo,
    tickBox,
    circleLeft,
    addToCart
  },
  size: {
    mini,
    tiny,
    small,
    large,
    xLarge,
    xxxLarge,
    customSize
  },
  baseClassname,
  spinLogo
}) {
  return (
    <div className={classNames([baseClassname],{
      "mini-icon": mini,
      "tiny-icon": tiny,
      "small-icon": small,
      "large-icon": large,
      "x-large-icon": xLarge,
      "xxx-large-icon": xxxLarge,
      "spin-logo": spinLogo
    })} style={{width: customSize}}>
      <IconRef 
        applogo={applogo} 
        zodiacLogo={zodiacLogo}
        plusRoundIcon={plusRoundIcon}
        locationLogo={locationLogo}
        callLogo={callLogo}
        starLogo={starLogo}
        tickBox={tickBox}
        circleLeft={circleLeft}
        addToCart={addToCart}
      />
    </div>
  )
}

export default SvgIcon;
