export const homeHeaderRoutes = [
  {
    name: "Home",
    route: "/home"
  },
  {
    name: "Services",
    route: "/services"
  },
  // {
  //   name: "Appointment",
  //   route: "/appointment"
  // },
  // {
  //   name: "Learn",
  //   route: "/learn"
  // },
  {
    name: "About us",
    route: "/about"
  },
  {
    name: "Contact us",
    route: "/contact"
  },
  // {
  //   name: "Pay",
  //   route: "/payment"
  // },
]